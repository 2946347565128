import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import PartnerPartnerPageBreadcrumbs from './components/PartnerPartnerPageBreadcrumbs';

const PartnerPartnerPage = () => {
  const params = useParams();
  const { partnerId1, partnerId2, partnerId3 } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <PartnerPartnerPageBreadcrumbs
          partnerId1={partnerId1!}
          partnerId2={partnerId2!}
          partnerId3={partnerId3!}
        />
      </Grid>
      <Grid item xs>
        <Outlet context={{ partnerId1, partnerId2, partnerId3 }} />
      </Grid>
    </Grid>
  );
};

export default PartnerPartnerPage;
