import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import {
  useGetOrganizationById,
  useGetOrganizationEmployeeAccountById,
} from '../../../../hooks/reactQuery';
import OrganizationEmployeeDetailsTab from '../OrganizationEmployeeDetailsTab';
import OrganizationEmployeeLoginsTab from '../OrganizationEmployeeLoginsTab';
import OrganizationEmployeeAuditLogsTab from '../OrganizationEmployeeAuditLogsTab';
import ACLEnum from '../../../../enums/ACLEnum';
import { useUser } from '../../../../contexts/UserContext';

export enum OrganizationEmployeeModuleTabEnum {
  Details = 'details',
  Logins = 'logins',
  AuditLogs = 'audit',
}
interface IOrganizationEmployeeModuleProps {
  activeTab: OrganizationEmployeeModuleTabEnum;
}
interface IOrganizationEmployeeModuleContext {
  organizationId: string;
  employeeAccountId: string;
}

const OrganizationEmployeeModule = (props: IOrganizationEmployeeModuleProps) => {
  const { activeTab } = props;
  const { userCan } = useUser();
  const { t } = useTranslations();
  const {
    organizationId,
    employeeAccountId,
  } = useOutletContext<IOrganizationEmployeeModuleContext>();
  const navigate = useNavigate();

  const { isLoading: isOrganizationLoading } = useGetOrganizationById(
    { organizationId },
  );

  const { isLoading: isEmployeeLoading } = useGetOrganizationEmployeeAccountById(
    { employeeAccountId },
  );

  const isLoading = isOrganizationLoading || isEmployeeLoading;

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: OrganizationEmployeeModuleTabEnum,
  ) => {
    const nextPath = `${
      PageRouteEnum.Employee
    }/${organizationId}/${employeeAccountId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [organizationId, employeeAccountId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === OrganizationEmployeeModuleTabEnum.Details;

  const canViewLogins = userCan(ACLEnum.EmployeeAccountViewVaultPasswords);
  const canViewAuditLogs = userCan(ACLEnum.EmployeeAccountViewAuditLogs);

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('organizationEmployeePage.tabs.employeeDetails')}
            value={OrganizationEmployeeModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          {canViewLogins && (
            <Tab
              label={t('organizationEmployeePage.tabs.logins')}
              value={OrganizationEmployeeModuleTabEnum.Logins}
              onClick={handleTabClick}
            />
          )}
          {canViewAuditLogs && (
            <Tab
              label={t('organizationEmployeePage.tabs.auditLogs')}
              value={OrganizationEmployeeModuleTabEnum.AuditLogs}
              onClick={handleTabClick}
            />
          )}
        </Tabs>
      </PanelHeader>
      <RenderIf condition={activeTab === OrganizationEmployeeModuleTabEnum.Details}>
        <OrganizationEmployeeDetailsTab
          employeeAccountId={employeeAccountId}
          organizationId={organizationId}
        />
      </RenderIf>
      <RenderIf
        condition={canViewLogins && activeTab === OrganizationEmployeeModuleTabEnum.Logins}
      >
        <OrganizationEmployeeLoginsTab employeeAccountId={employeeAccountId} />
      </RenderIf>
      <RenderIf
        condition={canViewAuditLogs && activeTab === OrganizationEmployeeModuleTabEnum.AuditLogs}
      >
        <OrganizationEmployeeAuditLogsTab employeeAccountId={employeeAccountId} />
      </RenderIf>
    </Panel>
  );
};

export default memo(OrganizationEmployeeModule);
