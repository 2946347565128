import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import ACLEnum from '../../../../enums/ACLEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import PartnerDetailsTab from '../PartnerDetailsTab';
import { useGetPartnerById } from '../../../../hooks/reactQuery';
import PartnerOrganizationsTab from '../PartnerOrganizationsTab';
import PartnerLicensesHistoryTab from '../PartnerLicensesHistoryTab';
import { useUser } from '../../../../contexts/UserContext';
import PartnerPartnerUsersTab from '../PartnerPartnerUsersTab';
import PartnerPartnersTab from '../PartnerPartnersTab';

export enum PartnerModuleTabEnum {
  Details = 'details',
  Organizations = 'organizations',
  Licenses = 'licenses',
  PartnerUsers = 'partner-users',
  Partners = 'partners'
}
interface IPartnerModuleProps {
  activeTab: PartnerModuleTabEnum;
}
interface IPartnerModuleContext {
  partnerId: string;
}

const PartnerModule = (props: IPartnerModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const { partnerId } = useOutletContext<IPartnerModuleContext>();
  const navigate = useNavigate();
  const { userCan } = useUser();

  const { isLoading } = useGetPartnerById(
    { partnerId },
  );

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: PartnerModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Partners}/${partnerId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [partnerId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === PartnerModuleTabEnum.Details;

  const canViewOrganizations = userCan(ACLEnum.PartnerViewOrganizations);
  const canViewLicensesHistory = userCan(ACLEnum.PartnerViewLicenseHistory);
  const canViewPartnerUsers = userCan(ACLEnum.PartnerUserView);
  const canViewPartners = userCan(ACLEnum.PartnerView);

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('partnerPage.tabs.partnerDetails')}
            value={PartnerModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          {canViewOrganizations && (
            <Tab
              label={t('partnerPage.tabs.organizations')}
              value={PartnerModuleTabEnum.Organizations}
              onClick={handleTabClick}
            />
          )}
          {canViewLicensesHistory && (
            <Tab
              label={t('partnerPage.tabs.licensesHistory')}
              value={PartnerModuleTabEnum.Licenses}
              onClick={handleTabClick}
            />
          )}
          {canViewPartnerUsers && (
            <Tab
              label={t('partnerPage.tabs.partnerUsers')}
              value={PartnerModuleTabEnum.PartnerUsers}
              onClick={handleTabClick}
            />
          )}
          {canViewPartners && (
            <Tab
              label={t('partnerPage.tabs.partners')}
              value={PartnerModuleTabEnum.Partners}
              onClick={handleTabClick}
            />
          )}
        </Tabs>
      </PanelHeader>
      <RenderIf condition={activeTab === PartnerModuleTabEnum.Details}>
        <PartnerDetailsTab partnerId={partnerId} />
      </RenderIf>
      <RenderIf
        condition={canViewOrganizations && activeTab === PartnerModuleTabEnum.Organizations}
      >
        <PartnerOrganizationsTab partnerId={partnerId} />
      </RenderIf>
      <RenderIf condition={canViewLicensesHistory && activeTab === PartnerModuleTabEnum.Licenses}>
        <PartnerLicensesHistoryTab partnerId={partnerId} />
      </RenderIf>
      <RenderIf condition={canViewPartnerUsers && activeTab === PartnerModuleTabEnum.PartnerUsers}>
        <PartnerPartnerUsersTab partnerId={partnerId} />
      </RenderIf>
      <RenderIf condition={canViewPartners && activeTab === PartnerModuleTabEnum.Partners}>
        <PartnerPartnersTab partnerId={partnerId} />
      </RenderIf>
    </Panel>
  );
};

export default memo(PartnerModule);
