import { SyntheticEvent } from 'react';
import {
  AB3, Grid, Link, S5, Typography, useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../enums/PageRouteEnum';
import { logout } from '../../services/authService';

const handleLogout = (e: SyntheticEvent) => {
  e.preventDefault();
  logout();
};

const UnauthenticatedStepHelperSection = () => {
  const { t } = useTranslations();
  return (
    <Grid
      item
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Grid item>
        <Typography variant="subtitle1" color={S5}>
          {t('unauthenticatedStepHelperSection.haveProblem')}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" color={S5}>
          {t('unauthenticatedStepHelperSection.contactFirstPart')}
          &nbsp;
          <Link
            href={PageRouteEnum.Login}
            color={AB3}
            variant="caption"
            onClick={handleLogout}
          >
            {t('unauthenticatedStepHelperSection.logout')}
          </Link>
          &nbsp;
          {t('unauthenticatedStepHelperSection.contactSecondPart')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UnauthenticatedStepHelperSection;
