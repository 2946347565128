import { ReactNode } from 'react';
import { Container } from '@uniqkey-frontend/shared-app';

interface IUnauthorizedLayoutProps {
  children: ReactNode,
}

const UnauthorizedLayout = (props: IUnauthorizedLayoutProps) => {
  const { children } = props;
  return (
    <Container component="main" className="min-height-100-vh">
      {children}
    </Container>
  );
};

export default UnauthorizedLayout;
