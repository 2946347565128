import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import PartnerUserPageBreadcrumbs from './components/PartnerUserBreadcrumbs';

const PartnerUserPage = () => {
  const params = useParams();
  const { partnerUserId } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <PartnerUserPageBreadcrumbs partnerUserId={partnerUserId!} />
      </Grid>
      <Grid item xs>
        <Outlet context={{ partnerUserId }} />
      </Grid>
    </Grid>
  );
};

export default PartnerUserPage;
