import { memo } from 'react';
import { PanelContent } from '@uniqkey-frontend/shared-app';
import useOrganizationsTable from '../../../../hooks/tables/useOrganizationsTable';
import OrganizationsTable from '../../../../components/tables/OrganizationsTable';

interface IPartnerOrganizationsTabProps {
  partnerId: string;
}

const PartnerOrganizationsTab = (props: IPartnerOrganizationsTabProps) => {
  const { partnerId } = props;

  const {
    selectedOrganizations,
    ...restTableProps
  } = useOrganizationsTable({
    persistentFilters: { partnerId },
    noDataMessageKey: 'partnerPage.organizationsTab.noData',
  });

  return (
    <PanelContent p={0}>
      <OrganizationsTable
        partnerId={partnerId}
        selectedOrganizations={selectedOrganizations}
        {...restTableProps}
      />
    </PanelContent>
  );
};

export default memo(PartnerOrganizationsTab);
