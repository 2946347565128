import {
  useCallback,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  OrganizationHistoricLicenseOrderProperty,
  GetOrganizationHistoricLicenseDataResponseModel,
} from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import usePartnerOrganizationsAPI from '../../usePartnerOrganizationsAPI';
import {
  IGetOrganizationLicensesHistoryParams,
  TGetOrganizationLicensesHistoryMethod,
} from '../../usePartnerOrganizationsAPI/interfaces';
import {
  IOrganizationLicensesHistoryTableRow,
} from '../../../components/tables/OrganizationLicensesHistoryTable';

interface IPersistentFilters extends Pick<
  IGetOrganizationLicensesHistoryParams, 'organizationId'
> {}

export interface IUseOrganizationLicensesHistoryTableProps {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationLicensesHistoryTableReturn extends
  Omit<IUseTableReturn<IOrganizationLicensesHistoryTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseTableFetchReturn<GetOrganizationHistoricLicenseDataResponseModel>, 'data'>
{
  organizationLicensesHistory: IUseTableFetchReturn<
    GetOrganizationHistoricLicenseDataResponseModel
  >['data'];
  selectedOrganizationLicensesHistory: IUseTableReturn<
    IOrganizationLicensesHistoryTableRow
  >['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationLicensesHistoryParams,
    'page' | 'pageLength' | 'organizationId' | 'orderPropertyName' | 'isDescending' | 'cancelToken'
  >,
  Pick<IUseTableReturn<
    IOrganizationLicensesHistoryTableRow
  >, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetOrganizationLicensesHistoryParams['page'];
  perPage: IGetOrganizationLicensesHistoryParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: OrganizationHistoricLicenseOrderProperty },
  ),
  columnOrderDirection: true,
};

const useOrganizationLicensesHistoryTable = (
  props: IUseOrganizationLicensesHistoryTableProps,
): IUseOrganizationLicensesHistoryTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getOrganizationLicensesHistory } = usePartnerOrganizationsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedOrganizationLicensesHistory,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationLicensesHistoryTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: organizationLicensesHistory, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetOrganizationLicensesHistoryParams,
    TGetOrganizationLicensesHistoryMethod,
    GetOrganizationHistoricLicenseDataResponseModel
  >({
    request: getOrganizationLicensesHistory,
    params: {
      page: activePage,
      pageLength: perPage,
      orderPropertyName: OrganizationHistoricLicenseOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof OrganizationHistoricLicenseOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    organizationLicensesHistory,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedOrganizationLicensesHistory,
    localization,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationLicensesHistoryTable;
