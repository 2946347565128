enum ReactQueryKeyEnum {
  Partner = 'partner',
  CurrentUser = 'currentUser',
  Organization = 'organization',
  OrganizationEmployee = 'organizationEmployee',
  ContactPerson = 'contactPerson',
  PartnerUser = 'partnerUser',
  Group = 'group',
  GroupUsers= 'groupUsers',
  ACLs = 'ACLs'
}

export default ReactQueryKeyEnum;
