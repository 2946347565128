import { useMemo, memo } from 'react';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
  formatDate,
  DateTimeFormatEnum,
} from '@uniqkey-frontend/shared-app';
import {
  GetVerifiedDomainsResponseModel,
} from '@uniqkey-backend-partner/api-client';

export interface IOrganizationVerifiedDomainsTableRow extends GetVerifiedDomainsResponseModel {
  processedDate: string | null;
}

interface IOrganizationVerifiedDomainsTableProps extends
  Omit<IUseTableReturn<IOrganizationVerifiedDomainsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetVerifiedDomainsResponseModel>, 'data' | 'refetch'>
{
  organizationVerifiedDomains: IUseTableFetchReturn<
    GetVerifiedDomainsResponseModel
  >['data'];
  selectedOrganizationVerifiedDomains: IUseTableReturn<
    IOrganizationVerifiedDomainsTableRow
  >['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationVerifiedDomainsTableRow> = {
  selection: false,
  sorting: false,
};

const OrganizationVerifiedDomainsTable = (props: IOrganizationVerifiedDomainsTableProps) => {
  const {
    organizationVerifiedDomains: preOrganizationVerifiedDomains,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedOrganizationVerifiedDomains,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();

  const organizationVerifiedDomains = useMemo(() => preOrganizationVerifiedDomains.map(
    (verifiedDomain) => ({
      ...verifiedDomain,
      processedDate: formatDate({ date: verifiedDomain.createdAt }, DateTimeFormatEnum.DEFAULT),
    }),
  ), [preOrganizationVerifiedDomains]);

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationPage.verifiedDomainsTab.domainName'),
      field: 'domainName',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.domainName}
        </TypographyWithTooltip>
      ),
      width: '80%',
    },
    {
      title: t('organizationPage.verifiedDomainsTab.createdAt'),
      field: 'createdAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.processedDate}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={organizationVerifiedDomains}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedOrganizationVerifiedDomains}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="verifiedDomainId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationVerifiedDomainsTable);
