import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import PartnerPartnerUserPageBreadcrumbs from './components/PartnerPartnerUserPageBreadcrumbs';

const PartnerPartnerUserPage = () => {
  const params = useParams();
  const {
    partnerId1, partnerId2, partnerId3, partnerUserId,
  } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <PartnerPartnerUserPageBreadcrumbs
          partnerUserId={partnerUserId!}
          partnerId1={partnerId1!}
          partnerId2={partnerId2!}
          partnerId3={partnerId3!}
        />
      </Grid>
      <Grid item xs>
        <Outlet context={{
          partnerUserId, partnerId1, partnerId2, partnerId3,
        }}
        />
      </Grid>
    </Grid>
  );
};

export default PartnerPartnerUserPage;
