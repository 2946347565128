import { useCallback, useMemo, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  buildFilterOptionsAsObject,
  usePubSub,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { PartnerUserRole } from '@uniqkey-backend-partner/api-client';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import { IGetPartnerUsersParams } from '../../../../hooks/usePartnerUsersAPI/interfaces';
import { getUserRoleOptions } from '../../../../helpers/userRole';

export interface IPartnerUsersListFilterSubmitResult {
  roleFilter: IGetPartnerUsersParams['roleFilter'];
}

interface IPartnerUsersListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IPartnerUsersListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IPartnerUsersListFilterSubmitResult;
  role: PartnerUserRole;
}

type TRoleFilterOption = {
  label: string;
  value: PartnerUserRole;
} | null;

interface IFormValues {
  roleFilter: TRoleFilterOption;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const PartnerUsersListFilter = (props: IPartnerUsersListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues, role,
  } = props;
  const { t } = useTranslations();

  const [roleFilterOptions, roleFilterOptionsAsObject] = useMemo(() => {
    const options = getUserRoleOptions(t, role);
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [role, t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      roleFilter: roleFilterOptionsAsObject[
        initialValues.roleFilter as keyof typeof roleFilterOptionsAsObject
      ] ?? null,
    },
  });

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const { roleFilter } = value;
    onSubmit({
      roleFilter: roleFilter?.value,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('roleFilter', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      roleFilter: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid item container alignItems="center">
            <Grid item xs={3}>
              <Typography>
                {t('partnerUsersFilter.role.label')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <ReactHookFormAutocomplete
                t={t}
                name="roleFilter"
                placeholder={t('partnerUsersFilter.role.placeholder')}
                options={roleFilterOptions}
                control={control}
              />
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(PartnerUsersListFilter);
