import * as Sentry from '@sentry/react';
import type { Breadcrumb } from '@sentry/types';
import config from '../../config';

export const initSentry = () => {
  Sentry.init({
    dsn: config.sentryDSN,
    environment: config.environment,
  });
};

export const setUserContext = Sentry.setUser;

export const clearContexts = () => {
  Sentry.configureScope((scope) => scope.clear());
  Sentry.setUser(null);
};

export const logException = (exception: unknown, breadcrumb?: Breadcrumb) => {
  Sentry.withScope((scope) => {
    if (breadcrumb) {
      scope.addBreadcrumb(breadcrumb);
    }
    Sentry.captureException(exception);
  });
};
