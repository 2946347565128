import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import { useGetPartnerById, useGetOrganizationById } from '../../../../hooks/reactQuery';
import OrganizationDetailsTab from '../../../OrganizationPage/components/OrganizationDetailsTab';
import ACLEnum from '../../../../enums/ACLEnum';
import { useUser } from '../../../../contexts/UserContext';
import OrganizationVerifiedDomainsTab from
  '../../../OrganizationPage/components/OrganizationVerifiedDomainsTab';
import OrganizationAdminsTab from
  '../../../OrganizationPage/components/OrganizationAdminsTab';
import OrganizationLicensesHistoryTab from
  '../../../OrganizationPage/components/OrganizationLicensesHistoryTab';
import OrganizationEmployeesTab from
  '../../../OrganizationPage/components/OrganizationEmployeesTab';

export enum PartnerOrganizationModuleTabEnum {
  Details = 'details',
  Domains = 'domains',
  Admins = 'admins',
  Licenses = 'licenses',
  Employees = 'employees'
}
interface IPartnerOrganizationModuleProps {
  activeTab: PartnerOrganizationModuleTabEnum;
}
interface IPartnerOrganizationModuleContext {
  partnerId: string;
  organizationId: string;
}

const PartnerOrganizationModule = (props: IPartnerOrganizationModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const { userCan } = useUser();
  const context = useOutletContext<IPartnerOrganizationModuleContext>();
  const navigate = useNavigate();

  const {
    partnerId,
    organizationId,
  } = context;

  const { isLoading: isPartnerLoading } = useGetPartnerById(
    { partnerId },
  );

  const { isLoading: isOrganizationLoading } = useGetOrganizationById(
    { organizationId },
  );

  const isLoading = isPartnerLoading || isOrganizationLoading;

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: PartnerOrganizationModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Partners}/${partnerId}/${organizationId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [partnerId, organizationId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === PartnerOrganizationModuleTabEnum.Details;

  const canViewDomains = userCan(ACLEnum.OrganizationViewDomains);
  const canViewAdmins = userCan(ACLEnum.OrganizationViewAdmins);
  const canViewLicensesHistory = userCan(ACLEnum.OrganizationViewLicenseHistory);
  const canViewEmployees = userCan(ACLEnum.EmployeeAccountView);

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('organizationPage.tabs.organizationDetails')}
            value={PartnerOrganizationModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          {canViewDomains && (
            <Tab
              label={t('organizationPage.tabs.verifiedDomains')}
              value={PartnerOrganizationModuleTabEnum.Domains}
              onClick={handleTabClick}
            />
          )}
          {canViewAdmins && (
            <Tab
              label={t('organizationPage.tabs.admins')}
              value={PartnerOrganizationModuleTabEnum.Admins}
              onClick={handleTabClick}
            />
          )}
          {canViewLicensesHistory && (
            <Tab
              label={t('organizationPage.tabs.licensesHistory')}
              value={PartnerOrganizationModuleTabEnum.Licenses}
              onClick={handleTabClick}
            />
          )}
          {canViewEmployees && (
            <Tab
              label={t('organizationPage.tabs.employees')}
              value={PartnerOrganizationModuleTabEnum.Employees}
              onClick={handleTabClick}
            />
          )}
        </Tabs>
      </PanelHeader>
      <RenderIf condition={activeTab === PartnerOrganizationModuleTabEnum.Details}>
        <OrganizationDetailsTab organizationId={organizationId} />
      </RenderIf>
      <RenderIf
        condition={canViewDomains && activeTab === PartnerOrganizationModuleTabEnum.Domains}
      >
        <OrganizationVerifiedDomainsTab organizationId={organizationId} />
      </RenderIf>
      <RenderIf condition={canViewAdmins && activeTab === PartnerOrganizationModuleTabEnum.Admins}>
        <OrganizationAdminsTab organizationId={organizationId} />
      </RenderIf>
      <RenderIf
        condition={
          canViewLicensesHistory && activeTab === PartnerOrganizationModuleTabEnum.Licenses
        }
      >
        <OrganizationLicensesHistoryTab organizationId={organizationId} />
      </RenderIf>
      <RenderIf
        condition={canViewEmployees && activeTab === PartnerOrganizationModuleTabEnum.Employees}
      >
        <OrganizationEmployeesTab organizationId={organizationId} partnerId={partnerId} />
      </RenderIf>
    </Panel>
  );
};

export default memo(PartnerOrganizationModule);
