import {
  useCallback, useEffect, useMemo, useState, ReactNode,
} from 'react';
import { useQueryClient } from 'react-query';
import { useSnackbar, useTranslations, usePubSub } from '@uniqkey-frontend/shared-app';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import LocalStorageKeyEnum from '../../enums/LocalStorageKeyEnum';
import ACLEnum from '../../enums/ACLEnum';
import UserContext, { IUserContext } from '.';
import useUserActivityChecker from '../../hooks/useUserActivityChecker';
import { useGetCurrentUserACLs, useGetCurrentUser } from '../../hooks/reactQuery';
import {
  logout,
  isAuthenticated as isInitialAuthenticated,
  ILogoutParams,
} from '../../services/authService';
import { clearContexts, logException, setUserContext } from '../../services/sentryService';
import { triggerTokensRefresh } from '../../services/tokensManager';

// const CURRENT_USER_REACT_QUERY_STALE_TIME = 60 * 1000;
const CURRENT_USER_REACT_QUERY_REFETCH_INTERVAL = 5 * 1000;

interface IUserContextParams {
  children: ReactNode,
}

const UserProviderContext = (props: IUserContextParams) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => !!isInitialAuthenticated());

  const { t, currentLanguage, setLanguage } = useTranslations();
  const { showError } = useSnackbar();
  const queryClient = useQueryClient();

  const handleLogin = useCallback(() => {
    setIsAuthenticated(true);
  }, []);

  const handleLogout = useCallback((message: string, data: Partial<ILogoutParams>) => {
    setIsAuthenticated((prevIsAuthenticated) => {
      if (!prevIsAuthenticated) {
        return false;
      }
      const { showMessage } = data ?? {};
      if (showMessage) {
        showError({ text: t('common.unauthorizedError') });
      }
      queryClient.clear();
      return false;
    });
  }, [showError, queryClient, t]);

  usePubSub(PubSubEventEnum.LOGIN, handleLogin);

  usePubSub(PubSubEventEnum.LOGOUT, handleLogout);

  useUserActivityChecker(isAuthenticated);

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    refetch: refetchCurrentUser,
  } = useGetCurrentUser({
    onSuccess: async (data) => {
      if (currentLanguage === data.language) {
        return undefined;
      }
      return setLanguage(data.language);
    },
    onError: (e) => {
      logException(e, {
        message: 'UserProviderContext/useGetCurrentUser exception',
      });
      logout({ showMessage: true });
    },
    enabled: isAuthenticated,
    /*
      // will keep the query fresh and won't refetch so often
      staleTime: CURRENT_USER_REACT_QUERY_STALE_TIME,
    */
    // TODO: remove 'refetchInterval' when WS are added, revert to stale time instead
    // refetch current user to track role and ACLs changes
    refetchInterval: (user) => {
      if (!user?.loginVerified) {
        // do not refetch current user if he is logged out, or he is logged in but not authenticated
        return false;
      }
      return CURRENT_USER_REACT_QUERY_REFETCH_INTERVAL;
    },
  });

  const handleRefetchCurrentUser = useCallback(() => refetchCurrentUser(), [refetchCurrentUser]);
  usePubSub(PubSubEventEnum.REFETCH_CURRENT_USER, handleRefetchCurrentUser);

  const {
    masterPasswordSet = false,
    twoFactorSet = false,
    loginVerified = false,
    stateChanged = false,
    role,
  } = currentUser ?? {};

  const canLoadACLs = isAuthenticated && loginVerified;

  const {
    data: preACLs,
    isLoading: areACLsLoading,
    refetch: refetchACLs,
  } = useGetCurrentUserACLs({
    onError: (e) => {
      logException(e, {
        message: 'UserProviderContext/useGetCurrentUserACLs exception',
      });
      logout({ showMessage: true });
    },
    enabled: canLoadACLs,
  });

  const handleTokensRefresh = useCallback(() => Promise.all([
    refetchCurrentUser(),
    canLoadACLs && refetchACLs(),
  ]), [refetchCurrentUser, canLoadACLs, refetchACLs]);
  usePubSub(PubSubEventEnum.TOKENS_REFRESHED, handleTokensRefresh);

  useEffect(() => {
    if (!canLoadACLs) {
      return;
    }
    refetchACLs(); // trigger refetch on 'role' changes
  }, [role, canLoadACLs, refetchACLs]);

  useEffect(() => {
    if (!stateChanged) {
      return;
    }
    triggerTokensRefresh();
  }, [stateChanged]);

  const ACLsAsSet = useMemo<Set<ACLEnum>>(
    () => new Set(preACLs ? preACLs.accessRights : []),
    [preACLs],
  );

  const userCan = useCallback<IUserContext['userCan']>(
    (ACL) => ACLsAsSet.has(ACL),
    [ACLsAsSet],
  );

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.key === LocalStorageKeyEnum.JwtToken) {
        setIsAuthenticated(!!event.newValue);
      }
    };
    // NOTE: add listener storage - not working on the current tab
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, []);

  useEffect(() => {
    if (!currentUser) {
      clearContexts();
      return;
    }
    setUserContext({
      id: currentUser.id,
    });
  }, [currentUser]);

  const value = useMemo(() => ({
    currentUser: currentUser ?? null,
    isAuthenticated,
    isCurrentUserLoading,
    masterPasswordSet,
    twoFactorSet,
    loginVerified,
    stateChanged,
    areACLsLoading,
    userCan,
  }), [
    currentUser,
    isAuthenticated,
    isCurrentUserLoading,
    masterPasswordSet,
    twoFactorSet,
    loginVerified,
    stateChanged,
    areACLsLoading,
    userCan,
  ]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProviderContext;
