import { useCallback } from 'react';
import { GetAuditLogsResponse } from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { TGetAuditLogsMethod } from './interfaces';
import {
  auditLogPayloadExtractor,
  dataExtractor,
} from '../../helpers/apiClients';

const useAuditLogsAPI = () => {
  const { auditLogsAPIClient } = useApiClients();

  const getAuditLogs = useCallback<TGetAuditLogsMethod>(
    (params) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        partnerUserId = undefined,
        beforeDate = undefined,
        afterDate = undefined,
        cancelToken = undefined,
      } = params;
      return auditLogsAPIClient
        .apiV1AuditLogGet(
          page,
          pageLength,
          partnerId,
          partnerUserId,
          beforeDate,
          afterDate,
          { cancelToken },
        )
        .then(dataExtractor<GetAuditLogsResponse>())
        .then((response) => ({
          ...response,
          data: response.data!.map(((el) => auditLogPayloadExtractor(el))),
        }));
    },
    [auditLogsAPIClient],
  );

  return ({
    getAuditLogs,
  });
};

export default useAuditLogsAPI;
