enum PageRouteEnum {
  Login = '/login',
  SetupMasterPassword = '/setup-password',
  SetupTwoFA = '/setup-2fa',
  LoginTwoFA = '/login-2fa',
  SetupPermanentPassword = '/setup-password',
  Partners = '/partners',
  Partner = '/partners/:partnerId/*',
  PartnerOrganization = '/partners/:partnerId/:organizationId/*',
  PartnerPartners = 'partners/:partnerId1/:partnerId2/:partnerId3/*',
  Organizations = '/organizations',
  Organization = '/organizations/:organizationId/*',
  Groups = '/groups',
  Group = '/groups/:groupId/*',
  Employee = '/employee',
  OrganizationEmployee = '/employee/:organizationId/:employeeAccountId/*',
  PartnerOrganizationEmployee = '/partner-organization-employee',
  // eslint-disable-next-line max-len
  PartnersOrganizationEmployee = '/partner-organization-employee/:partnerId/:organizationId/:employeeAccountId/*',
  User = '/user',
  PartnerPartnerUser = '/user/:partnerId1/:partnerId2/:partnerId3/:partnerUserId/*',
  PartnerUsers = '/partner-users',
  PartnerUser = '/partner-users/:partnerUserId/*',
  Audit = '/audit',
  Profile = '/profile',
  Roles = '/roles',
  Role = '/roles/:role',
}

export default PageRouteEnum;
