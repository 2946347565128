import { createContext, useContext } from 'react';
import { MeResponse } from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../../enums/ACLEnum';

export interface ICurrentUser extends MeResponse {}

export interface IUserContext {
  currentUser: ICurrentUser | null;
  isAuthenticated: boolean;
  isCurrentUserLoading: boolean;
  masterPasswordSet: boolean;
  twoFactorSet: boolean;
  loginVerified: boolean;
  stateChanged: boolean;
  areACLsLoading: boolean;
  userCan: (ACL: ACLEnum) => boolean;
}

const defaultContext: IUserContext = {
  currentUser: null,
  isAuthenticated: false,
  isCurrentUserLoading: true,
  masterPasswordSet: false,
  twoFactorSet: false,
  loginVerified: false,
  stateChanged: false,
  areACLsLoading: true,
  userCan: () => false,
};

const UserContext = createContext<IUserContext>(defaultContext);

UserContext.displayName = 'UserContext';

export default UserContext;

export const useUser = (): IUserContext => useContext<IUserContext>(UserContext);
