import { Outlet } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';

const AuditLogsPage = () => (
  <Grid container flexDirection="column" className="min-height-100-percent">
    <Grid item xs>
      <Outlet />
    </Grid>
  </Grid>
);

export default AuditLogsPage;
