import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  GroupsResponse,
  CreateGroupRequest,
  NoContentResult,
  GroupByIdResponse,
  UpdateGroupRequest,
  AddUsersToGroupRequest,
  AddUsersToGroupResponse,
  GroupUsersResponse,
  RemoveUsersFromGroupRequest,
  RemoveUsersFromGroupResponse,
} from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';
import { TGetGroupsParams, TGetGroupUsersMethod } from './interfaces';

const useGroupsAPI = () => {
  const { groupsAPIClient } = useApiClients();

  const getGroups = useCallback<TGetGroupsParams>(
    (params) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        roleFilter = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return groupsAPIClient.apiV1GroupsGet(
        page,
        pageLength,
        partnerId,
        roleFilter,
        searchQuery,
        orderPropertyName,
        isDescending,
        // @ts-ignore TODO: NEXTGEN-5955
        { cancelToken },
      ).then(dataExtractor<GroupsResponse>());
    },
    [groupsAPIClient],
  );

  const createGroup = useCallback(
    (params: CreateGroupRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => groupsAPIClient
      .apiV1GroupsPost(params, options)
      .then(dataExtractor<NoContentResult>()),
    [groupsAPIClient],
  );

  const getGroupById = useCallback(
    (groupId: string, options?: AxiosRequestConfig)
      : Promise<GroupByIdResponse> => groupsAPIClient
      .apiV1GroupsGroupIdGet(groupId, options)
      .then(dataExtractor<GroupByIdResponse>()),
    [groupsAPIClient],
  );

  const updateGroup = useCallback(
    (updateGroupRequest: UpdateGroupRequest, options?: AxiosRequestConfig)
      :Promise<NoContentResult> => groupsAPIClient
      .apiV1GroupsPut(updateGroupRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [groupsAPIClient],
  );

  const addUsersToGroup = useCallback(
    (addUsersToGroupRequest: AddUsersToGroupRequest, options?: AxiosRequestConfig)
      :Promise<AddUsersToGroupResponse> => groupsAPIClient
      .apiV1GroupsAddUsersToGroupPost(addUsersToGroupRequest, options)
      .then(dataExtractor<AddUsersToGroupResponse>()),
    [groupsAPIClient],
  );

  const getGroupUsers = useCallback<TGetGroupUsersMethod>(
    (params) => {
      const {
        page,
        pageLength,
        groupId = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        signal = undefined,
        cancelToken = undefined,
      } = params;
      return groupsAPIClient.apiV1GroupsGroupUsersGet(
        page,
        pageLength,
        groupId,
        orderPropertyName,
        isDescending,
        { cancelToken, signal },
      ).then(dataExtractor<GroupUsersResponse>());
    },
    [groupsAPIClient],
  );

  const removeUsersFromGroup = useCallback(
    (removeUsersFromGroupRequest: RemoveUsersFromGroupRequest, options?: AxiosRequestConfig)
      :Promise<RemoveUsersFromGroupResponse> => groupsAPIClient
      .apiV1GroupsRemoveUsersFromGroupPost(removeUsersFromGroupRequest, options)
      .then(dataExtractor<RemoveUsersFromGroupResponse>()),
    [groupsAPIClient],
  );

  return ({
    getGroups,
    createGroup,
    getGroupById,
    updateGroup,
    addUsersToGroup,
    getGroupUsers,
    removeUsersFromGroup,
  });
};

export default useGroupsAPI;
