import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import PartnerPageBreadcrumbs from './components/PartnerPageBreadcrumbs';

const PartnerPage = () => {
  const params = useParams();
  const { partnerId } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <PartnerPageBreadcrumbs partnerId={partnerId!} />
      </Grid>
      <Grid item xs>
        <Outlet context={{ partnerId }} />
      </Grid>
    </Grid>
  );
};

export default PartnerPage;
