import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  CreatePartnerRequest,
  NoContentResult,
  GetPartnersResponse,
  GetPartnerByIdResponse,
  UpdatePartnerRequest,
  GetPartnerHistoricLicenseDataResponse,
  ExportPartnerLicenseDataResponse,
  ExportPeriod,
} from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';
import { TGetPartnerLicensesHistoryMethod, TGetPartnersMethod } from './interfaces';

const usePartnersAPI = () => {
  const { partnersAPIClient } = useApiClients();

  const createPartner = useCallback(
    (params: CreatePartnerRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => partnersAPIClient
      .apiV1PartnersPost(params, options)
      .then(dataExtractor<NoContentResult>()),
    [partnersAPIClient],
  );

  const getPartners = useCallback<TGetPartnersMethod>(
    (params) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        countryFilter = undefined,
        typeFilter = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return partnersAPIClient.apiV1PartnersGet(
        page,
        pageLength,
        partnerId,
        countryFilter,
        typeFilter,
        searchQuery,
        orderPropertyName,
        isDescending,
        { cancelToken },
      ).then(dataExtractor<GetPartnersResponse>());
    },
    [partnersAPIClient],
  );

  const getPartnerById = useCallback(
    (partnerId: string, options?: AxiosRequestConfig)
      : Promise<GetPartnerByIdResponse> => partnersAPIClient
      .apiV1PartnersPartnerIdGet(partnerId, options)
      .then(dataExtractor<GetPartnerByIdResponse>()),
    [partnersAPIClient],
  );

  const updatePartner = useCallback(
    (updatePartnerRequest?: UpdatePartnerRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => partnersAPIClient
      .apiV1PartnersPut(updatePartnerRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [partnersAPIClient],
  );

  const getPartnerLicensesHistory = useCallback<TGetPartnerLicensesHistoryMethod>(
    (params) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return partnersAPIClient.apiV1PartnersGetHistoricLicenseDataGet(
        page,
        pageLength,
        partnerId,
        orderPropertyName,
        isDescending,
        { cancelToken },
      ).then(dataExtractor<GetPartnerHistoricLicenseDataResponse>());
    },
    [partnersAPIClient],
  );

  const getPartnerExportLicenseData = useCallback(
    (partnerId: string, exportPeriod: ExportPeriod, options?: AxiosRequestConfig)
      : Promise<ExportPartnerLicenseDataResponse> => partnersAPIClient
      .apiV1PartnersExportLicenseDataGet(partnerId, exportPeriod, options)
      .then(dataExtractor<ExportPartnerLicenseDataResponse>()),
    [partnersAPIClient],
  );

  return ({
    createPartner,
    getPartners,
    getPartnerById,
    updatePartner,
    getPartnerLicensesHistory,
    getPartnerExportLicenseData,
  });
};

export default usePartnersAPI;
