import { useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import { PartnerUserRole } from '@uniqkey-backend-partner/api-client';
import RolePageBreadcrumbs from './components/RolePageBreadcrumbs';
import RoleModule from './components/RoleModule';

type TRouterParams = Record<'role', PartnerUserRole>;

const RolePage = () => {
  const params = useParams<TRouterParams>();
  const { role } = params;

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item mb={1}>
        <RolePageBreadcrumbs role={role!} />
      </Grid>
      <Grid item xs>
        <RoleModule role={role!} />
      </Grid>
    </Grid>
  );
};

export default RolePage;
