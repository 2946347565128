import { useCallback, memo } from 'react';
import {
  Grid,
  useTranslations,
  usePopper,
  FilterButton,
  SearchableTextField,
  Divider,
  Tooltip,
  PanelContent,
  ISearchableTextFieldProps,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import useOrganizationEmployeesTable from '../../../../hooks/tables/useOrganizationEmployeesTable';
import OrganizationEmployeesTable from '../../../../components/tables/OrganizationEmployeesTable';
import OrganizationEmployeesTabFilter, {
  IOrganizationEmployeesTabFilterSubmitResult,
} from './components/OrganizationEmployeesTabFilter';

interface IOrganizationEmployeesTabProps {
  organizationId: string;
  partnerId?: string;
}

const OrganizationEmployeesTab = (props: IOrganizationEmployeesTabProps) => {
  const { organizationId, partnerId } = props;
  const { t } = useTranslations();

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const {
    selectedEmployees,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps
  } = useOrganizationEmployeesTable({
    persistentFilters: { organizationId },
    noDataMessageKey: 'organizationPage.employeesTab.noData',
  });

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((
    updatedValues: IOrganizationEmployeesTabFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <OrganizationEmployeesTable
        partnerId={partnerId}
        organizationId={organizationId}
        selectedEmployees={selectedEmployees}
        {...restTableProps}
      />
      <OrganizationEmployeesTabFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
    </PanelContent>
  );
};

export default memo(OrganizationEmployeesTab);
