import { useCallback, useState } from 'react';
import {
  Grid,
  Panel,
  Box,
  Button,
  FilterButton,
  useTranslations,
  SearchableTextField,
  Tooltip,
  InviteUserIcon,
  useSnackbar,
  usePopper,
  RenderIf,
  ISearchableTextFieldProps,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import { CreatePartnerRequest, MeResponse } from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../../enums/ACLEnum';
import CreatePartnerModal from './components/CreatePartnerModal';
import usePartnersAPI from '../../hooks/usePartnersAPI';
import PartnersTable from '../../components/tables/PartnersTable';
import usePartnersTable from '../../hooks/tables/usePartnersTable';
import PartnersListFilter, {
  IPartnersListFilterSubmitResult,
} from './components/PartnersListFilter';
import { useUser } from '../../contexts/UserContext';

const PartnersPage = () => {
  const { t } = useTranslations();
  const { createPartner } = usePartnersAPI();
  const { showSuccess, showError } = useSnackbar();
  const { currentUser, userCan } = useUser();
  const { partnerType } = currentUser ?? {} as MeResponse;

  const [isCreatePartnerModalOpen, setIsCreatePartnerModalOpen] = useState(false);
  const [isCreatePartnerLoading, setIsCreatePartnerLoading] = useState(false);

  const {
    selectedPartners,
    refetch,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps
  } = usePartnersTable({
    noDataMessageKey: 'partnersPage.noData',
  });

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const handleCreatePartnerModalOpen = useCallback(
    () => setIsCreatePartnerModalOpen(true),
    [],
  );

  const handleCreatePartnerModalClose = useCallback(
    () => setIsCreatePartnerModalOpen(false),
    [],
  );

  const handleCreatePartner = useCallback(async (
    partner: CreatePartnerRequest,
  ) => {
    try {
      setIsCreatePartnerLoading(true);
      await createPartner({ ...partner });
      showSuccess({
        text: t('createPartnerModal.partnerCreated'),
      });
      handleCreatePartnerModalClose();
      refetch();
    } catch (error) {
      showError({ text: t('common.somethingWentWrong') });
    } finally {
      setIsCreatePartnerLoading(false);
    }
  }, [createPartner, handleCreatePartnerModalClose, refetch, showError, showSuccess, t]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((updatedValues: IPartnersListFilterSubmitResult) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item mb={1}>
        <Panel>
          <Box p={1}>
            <Grid container justifyContent="space-between" alignItems="stretch">
              <Grid item xs={3} container flexWrap="nowrap">
                <Grid item>
                  <Tooltip title={t('common.filter')}>
                    <FilterButton
                      isFilterActive={isFilterActive}
                      numberOfActiveFilters={numberOfActiveFilters}
                      selected={isFilterOpen}
                      onChange={toggleIsFilterOpen}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={9} container justifyContent="flex-end" flexWrap="nowrap">
                <Grid item>
                  <SearchableTextField
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={t('common.search')}
                  />
                </Grid>
                <RenderIf condition={userCan(ACLEnum.PartnerCreate)}>
                  <Box mr={3} />
                  <Grid item>
                    <Button
                      icon={<InviteUserIcon />}
                      onClick={handleCreatePartnerModalOpen}
                    >
                      {t('partnersPage.createPartnerButton')}
                    </Button>
                  </Grid>
                </RenderIf>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      </Grid>
      <Grid item xs>
        <Panel>
          <PartnersTable
            selectedPartners={selectedPartners}
            {...restTableProps}
          />
        </Panel>
      </Grid>
      {isCreatePartnerModalOpen && (
      <CreatePartnerModal
        isOpen={isCreatePartnerModalOpen}
        isLoading={isCreatePartnerLoading}
        onSubmit={handleCreatePartner}
        onClose={handleCreatePartnerModalClose}
        type={partnerType}
      />
      )}
      <PartnersListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
        type={partnerType}
      />
    </Grid>
  );
};

export default PartnersPage;
