import {
  buildTablePreserverDecoderConfigItem,
  TablePreserverDecoderConfigItemTypeEnum as ConfigItemTypeEnum,
} from '@uniqkey-frontend/shared-app';
import { Ownership } from '@uniqkey-backend-partner/api-client';
import { omit } from 'lodash';

const ownershipDecoderConfig = buildTablePreserverDecoderConfigItem(ConfigItemTypeEnum.ENUM, {
  enumToCheck: Ownership,
});

const ownershipWithoutUnmanagedDecoderConfig = buildTablePreserverDecoderConfigItem(
  ConfigItemTypeEnum.ENUM,
  {
    enumToCheck: omit(Ownership, Ownership.Unmanaged),
  },
);

const tablePreservationConfigs = {
  ownership: ownershipDecoderConfig,
  ownershipWithoutUnmanaged: ownershipWithoutUnmanagedDecoderConfig,
};

export default tablePreservationConfigs;
