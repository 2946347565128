import { useCallback, useMemo, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  usePubSub,
  buildFilterOptionsAsObject,
  TSecurityScoreName,
  ISecurityScoreOption,
  getSecurityScoreOptions,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  Ownership,
} from '@uniqkey-backend-partner/api-client';
import PubSubEventEnum from '../../../../../../enums/PubSubEventEnum';
import {
  IGetOrganizationEmployeeAccountVaultPasswordsParams,
} from '../../../../../../hooks/useEmployeeAccountsAPI/interfaces';
import { OWNERSHIP_TRANSLATION_KEYS } from '../../../../../../constants';

export interface IOrganizationEmployeeLoginsTabFilterSubmitResult {
  ownership: IGetOrganizationEmployeeAccountVaultPasswordsParams['ownership'];
  securityScoreName: TSecurityScoreName;
}

interface IOrganizationEmployeeLoginsTabFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IOrganizationEmployeeLoginsTabFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IOrganizationEmployeeLoginsTabFilterSubmitResult;
}

type TOwnershipOption = {
  label: string;
  value: IOrganizationEmployeeLoginsTabFilterSubmitResult['ownership'];
} | null;

interface IFormValues {
  ownership: TOwnershipOption;
  securityScore: ISecurityScoreOption | null;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const OrganizationEmployeeLoginsTabFilter = (props: IOrganizationEmployeeLoginsTabFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues,
  } = props;
  const { t } = useTranslations();

  const [ownershipOptions, ownershipOptionsAsObject] = useMemo(() => {
    const options = [
      { label: t(OWNERSHIP_TRANSLATION_KEYS[Ownership.Employees]), value: Ownership.Employees },
      { label: t(OWNERSHIP_TRANSLATION_KEYS[Ownership.Groups]), value: Ownership.Groups },
    ];
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const [securityScoreOptions, securityScoreOptionsAsObject] = useMemo(() => {
    const options = getSecurityScoreOptions(t);
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      ownership: ownershipOptionsAsObject[
        initialValues.ownership as keyof typeof ownershipOptionsAsObject
      ] ?? null,
      securityScore: securityScoreOptionsAsObject[
        initialValues.securityScoreName as string as keyof typeof securityScoreOptionsAsObject
      ] ?? null,
    },
  });

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const { ownership, securityScore } = value;
    onSubmit({
      ownership: ownership?.value,
      securityScoreName: securityScore?.value,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('ownership', null, { shouldDirty: true });
    setValue('securityScore', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      ownership: null,
      securityScore: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationEmployeeLoginsTabFilter.managedBy.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="ownership"
                  options={ownershipOptions}
                  placeholder={t('organizationEmployeeLoginsTabFilter.managedBy.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationEmployeeLoginsTabFilter.securityScore.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="securityScore"
                  options={securityScoreOptions}
                  placeholder={t('organizationEmployeeLoginsTabFilter.securityScore.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(OrganizationEmployeeLoginsTabFilter);
