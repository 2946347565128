import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  TUseMutationContext,
  TUseMutationOptions,
  TUseQueryOptions,
} from '@uniqkey-frontend/shared-app';
import {
  GetContactPersonByIdResponse,
  NoContentResult,
  UpdateContactPersonRequest,
} from '@uniqkey-backend-partner/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useContactPersonAPI from '../../useContactPersonAPI';

interface IUseGetContactPersonByIdParams {
  contactPersonId: string;
}
export const useGetContactPersonById = (
  params: IUseGetContactPersonByIdParams,
  options: TUseQueryOptions<GetContactPersonByIdResponse> = {},
) => {
  const { contactPersonId } = params;
  const { getContactPersonById } = useContactPersonAPI();
  return useQuery<GetContactPersonByIdResponse>(
    [ReactQueryKeyEnum.ContactPerson, contactPersonId],
    ({ signal }) => getContactPersonById(contactPersonId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdateContactPersonParams {
  contactPersonId: string;
  useOptimisticUpdates?: boolean;
}

export const useUpdateContactPerson = (
  params: IUseUpdateContactPersonParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    Omit<UpdateContactPersonRequest, 'contactPersonId'>,
    TUseMutationContext<GetContactPersonByIdResponse>
  > = {},
) => {
  const { contactPersonId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateContactPerson } = useContactPersonAPI();
  const mutationKey = [ReactQueryKeyEnum.ContactPerson, contactPersonId];
  return useMutation(
    mutationKey,
    (contactPerson) => updateContactPerson(
      { ...contactPerson, contactPersonId },
    ),
    {
      onMutate: async (newContactPerson) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<
          GetContactPersonByIdResponse
        >(mutationKey);
        queryClient.setQueryData<GetContactPersonByIdResponse>(
          mutationKey,
          (oldContactPerson) => ({
            ...oldContactPerson,
            ...newContactPerson as GetContactPersonByIdResponse,
          }),
        );
        return { previousValue: previousValue as GetContactPersonByIdResponse };
      },
      onError: (err, contactPerson, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetContactPersonByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(mutationKey);
      },
      ...options,
    },
  );
};
