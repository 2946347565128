import { isAuthenticated } from '../authService';
import { getMeWithoutLogout } from '../../api/authApi';
import config from '../../config';
import enTranslations from '../../translations/index.json';

export const getBootstrapLanguage = async () => {
  const FALLBACK_LANGUAGE = 'en'; // TODO change from shared
  try {
    if (!isAuthenticated()) return FALLBACK_LANGUAGE;

    const profile = await getMeWithoutLogout();

    return profile.language;
  } catch (e) {
    return FALLBACK_LANGUAGE;
  }
};

export const getTranslationsInitOptions = () => {
  let options = {};
  if (config.useLocalTranslations) {
    options = {
      ...options,
      resources: { en: { translation: enTranslations } },
    };
  }
  return options;
};
