import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import PartnerOrganizationPageBreadcrumbs from './components/PartnerOrganizationPageBreadcrumbs';

const PartnerOrganizationPage = () => {
  const params = useParams();
  const {
    partnerId,
    organizationId,
  } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <PartnerOrganizationPageBreadcrumbs
          partnerId={partnerId!}
          organizationId={organizationId!}
        />
      </Grid>
      <Grid item xs>
        <Outlet context={{ partnerId, organizationId }} />
      </Grid>
    </Grid>
  );
};

export default PartnerOrganizationPage;
