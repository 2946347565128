import { useCallback } from 'react';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  IOrganizationEmployeeAccountAuditLog,
  IGetOrganizationEmployeeAccountAuditLogsParams,
  TGetOrganizationEmployeeAccountAuditLogsMethod,
} from '../../useEmployeeAccountsAPI/interfaces';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';
import {
  IOrganizationEmployeeAuditLogsTableRow,
} from '../../../components/tables/OrganizationEmployeeAuditLogsTable';

interface IPersistentFilters extends Pick<
  IGetOrganizationEmployeeAccountAuditLogsParams, 'employeeAccountId'
> {}

export interface IUseOrganizationEmployeeAuditLogsTableProps {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationEmployeeAuditLogsTableReturn extends
  Omit<
    IUseTableReturn<IOrganizationEmployeeAuditLogsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseTableFetchReturn<IOrganizationEmployeeAccountAuditLog>, 'data'>
{
  auditLogs: IUseTableFetchReturn<IOrganizationEmployeeAccountAuditLog>['data'];
  selectedAuditLogs: IUseTableReturn<IOrganizationEmployeeAuditLogsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationEmployeeAccountAuditLogsParams,
    'employeeAccountId' | 'page' | 'pageLength' | 'beforeDate' | 'afterDate' | 'cancelToken'
  >
{
  activePage: IGetOrganizationEmployeeAccountAuditLogsParams['page'];
  perPage: IGetOrganizationEmployeeAccountAuditLogsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
};

const useOrganizationEmployeeAuditLogsTable = (
  props: IUseOrganizationEmployeeAuditLogsTableProps,
): IUseOrganizationEmployeeAuditLogsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getOrganizationEmployeeAccountAuditLogs } = useEmployeeAccountsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    selectedRows: selectedAuditLogs,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationEmployeeAuditLogsTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: auditLogs, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetOrganizationEmployeeAccountAuditLogsParams,
    TGetOrganizationEmployeeAccountAuditLogsMethod,
    IOrganizationEmployeeAccountAuditLog
  >({
    request: getOrganizationEmployeeAccountAuditLogs,
    params: {
      ...persistentFilters,
      page: activePage,
      pageLength: perPage,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
  });

  return {
    auditLogs,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    selectedAuditLogs,
    localization,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationEmployeeAuditLogsTable;
