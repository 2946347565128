import { useCallback, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  usePubSub,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  GetOrganizationsResponseModel,
} from '@uniqkey-backend-partner/api-client';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import { IGetOrganizationsParams } from '../../../../hooks/usePartnerOrganizationsAPI/interfaces';
import { COUNTRIES_LIST } from '../../../../helpers/countries';

export interface IOrganizationsListFilterSubmitResult {
  countryFilter: IGetOrganizationsParams['countryFilter'];
}

interface IOrganizationsListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IOrganizationsListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IOrganizationsListFilterSubmitResult;
}

interface IFormValues {
  countryFilter: GetOrganizationsResponseModel['country'] | null;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const OrganizationsListFilter = (props: IOrganizationsListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues,
  } = props;
  const { t } = useTranslations();

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      countryFilter: initialValues.countryFilter ?? null,
    },
  });

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const { countryFilter } = value;
    onSubmit({
      countryFilter: countryFilter ?? undefined,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('countryFilter', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      countryFilter: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationsFilter.country.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="countryFilter"
                  placeholder={t('organizationsFilter.country.placeholder')}
                  options={COUNTRIES_LIST}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(OrganizationsListFilter);
