import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  TUseMutationContext,
  TUseMutationOptions,
  TUseQueryOptions,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerUserByIdResponse,
  DeletePartnerUsersBulkRequest,
  DeletePartnerUsersBulkResponse,
  NoContentResult,
  UpdatePartnerUserRequest,
  ChangeRoleRequest,
} from '@uniqkey-backend-partner/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import usePartnerUsersAPI from '../../usePartnerUsersAPI';

interface IUseGetPartnerUserByIdParams {
  partnerUserId: string;
}

export const useGetPartnerUserById = (
  params: IUseGetPartnerUserByIdParams,
  options: TUseQueryOptions<GetPartnerUserByIdResponse> = {},
) => {
  const { partnerUserId } = params;
  const { getPartnerUserById } = usePartnerUsersAPI();
  return useQuery<GetPartnerUserByIdResponse>(
    [ReactQueryKeyEnum.PartnerUser, partnerUserId],
    ({ signal }) => getPartnerUserById(partnerUserId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useDeletePartnerUsers = (
  options: TUseMutationOptions<
    DeletePartnerUsersBulkResponse,
    unknown,
    DeletePartnerUsersBulkRequest,
    void
  > = {},
) => {
  const queryClient = useQueryClient();
  const { deletePartnerUsers } = usePartnerUsersAPI();
  return useMutation((
    deletePartnerUsersBulkRequest,
  ) => deletePartnerUsers(deletePartnerUsersBulkRequest), {
    onSettled: (data, error, variables) => {
      variables.partnerUserIds.forEach((partnerUserId) => {
        queryClient.removeQueries([ReactQueryKeyEnum.PartnerUser, partnerUserId], { exact: true });
      });
    },
    ...options,
  });
};

interface IUseUpdatePartnerUserParams {
  partnerUserId: string;
  useOptimisticUpdates?: boolean;
}
export const useUpdatePartnerUser = (
  params: IUseUpdatePartnerUserParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    UpdatePartnerUserRequest,
    TUseMutationContext<GetPartnerUserByIdResponse>
  > = {},
) => {
  const { partnerUserId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updatePartnerUser } = usePartnerUsersAPI();
  const mutationKey = [ReactQueryKeyEnum.PartnerUser, partnerUserId];
  return useMutation(
    mutationKey,
    (partnerUser) => updatePartnerUser(
      { ...partnerUser, partnerUserId },
    ),
    {
      onMutate: async (newPartnerUser) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<
          GetPartnerUserByIdResponse
        >(mutationKey);
        queryClient.setQueryData<GetPartnerUserByIdResponse>(
          mutationKey,
          (oldPartnerUser) => ({
            ...oldPartnerUser,
            ...newPartnerUser as GetPartnerUserByIdResponse,
          }),
        );
        return { previousValue: previousValue as GetPartnerUserByIdResponse };
      },
      onError: (err, partnerUser, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetPartnerUserByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(mutationKey);
      },
      ...options,
    },
  );
};

interface IUseUpdatePartnerUserRoleParams {
  partnerUserId: string;
  useOptimisticUpdates?: boolean;
}
export const useUpdatePartnerUserRole = (
  params: IUseUpdatePartnerUserRoleParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    ChangeRoleRequest,
    TUseMutationContext<GetPartnerUserByIdResponse>
  > = {},
) => {
  const { partnerUserId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updatePartnerUserRole } = usePartnerUsersAPI();
  const mutationKey = [ReactQueryKeyEnum.PartnerUser, partnerUserId];
  return useMutation(
    mutationKey,
    (partnerUser) => updatePartnerUserRole(
      { ...partnerUser, partnerUserId },
    ),
    {
      onMutate: async (newPartnerUser) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<
          GetPartnerUserByIdResponse
        >(mutationKey);
        queryClient.setQueryData<GetPartnerUserByIdResponse>(
          mutationKey,
          (oldPartnerUser) => ({
            ...oldPartnerUser,
            ...newPartnerUser as GetPartnerUserByIdResponse,
          }),
        );
        return { previousValue: previousValue as GetPartnerUserByIdResponse };
      },
      onError: (err, partnerUser, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetPartnerUserByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(mutationKey);
      },
      ...options,
    },
  );
};
