import { useMemo, memo } from 'react';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
} from '@uniqkey-frontend/shared-app';
import {
  GetOrganizationAdminsResponseModel,
} from '@uniqkey-backend-partner/api-client';

export interface IOrganizationAdminsTableRow extends GetOrganizationAdminsResponseModel {}

interface IOrganizationAdminsTableProps extends
  Omit<IUseTableReturn<IOrganizationAdminsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetOrganizationAdminsResponseModel>, 'data' | 'refetch'>
{
  organizationAdmins: IUseTableFetchReturn<GetOrganizationAdminsResponseModel>['data'];
  selectedOrganizationAdmins: IUseTableReturn<IOrganizationAdminsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationAdminsTableRow> = {
  selection: false,
};

const OrganizationAdminsTable = (props: IOrganizationAdminsTableProps) => {
  const {
    organizationAdmins,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedOrganizationAdmins,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationPage.adminsTab.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '50%',
    },
    {
      title: t('organizationPage.adminsTab.email'),
      field: 'email',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.email}
        </TypographyWithTooltip>
      ),
      width: '50%',
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={organizationAdmins}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedOrganizationAdmins}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="id"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationAdminsTable);
