import { useCallback } from 'react';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { GetRolesResponseModel } from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  IGetRolesParams,
  TGetRolesMethod,
} from '../../usePermissionsAPI/interfaces';
import { IRolesTableRow } from '../../../components/tables/RolesTable';
import usePermissionsAPI from '../../usePermissionsAPI';

export interface IUseRolesTableProps {
  noDataMessageKey: string;
}

export interface IUseRolesTableReturn extends
  Omit<IUseTableReturn<IRolesTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetRolesResponseModel>, 'data'>
{
  roles: IUseTableFetchReturn<GetRolesResponseModel>['data'];
  selectedRoles: IUseTableReturn<IRolesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetRolesParams, 'page' | 'pageLength' | 'cancelToken'>
{
  activePage: IGetRolesParams['page'];
  perPage: IGetRolesParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
};

const useRolesTable = (props: IUseRolesTableProps): IUseRolesTableReturn => {
  const {
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getRoles } = usePermissionsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    selectedRows: selectedRoles,
    resetTableMethods,
    ...restTableProps
  } = useTable<IRolesTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: roles, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetRolesParams,
    TGetRolesMethod,
    GetRolesResponseModel
  >({
    request: getRoles,
    params: {
      page: activePage,
      pageLength: perPage,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
  });

  return {
    roles,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    selectedRoles,
    localization,
    ...restTableProps,
  };
};

export default useRolesTable;
