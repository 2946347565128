import { memo } from 'react';
import { PanelContent } from '@uniqkey-frontend/shared-app';
import usePartnerUsersTable from '../../../../hooks/tables/usePartnerUsersTable';
import PartnerUsersTable from '../../../../components/tables/PartnerUsersTable';

interface IPartnerPartnerUsersTabProps {
  partnerId: string;
}

const PartnerPartnerUsersTab = (props: IPartnerPartnerUsersTabProps) => {
  const { partnerId } = props;

  const tableProps = usePartnerUsersTable({
    persistentFilters: { partnerId },
    noDataMessageKey: 'partnerPartnerUserPage.partnerUsersTab.noData',
  });

  return (
    <PanelContent p={0}>
      <PartnerUsersTable
        partnerId={partnerId}
        {...tableProps}
      />
    </PanelContent>
  );
};

export default memo(PartnerPartnerUsersTab);
