import {
  TUseMutationContext,
  TUseMutationOptions,
} from '@uniqkey-frontend/shared-app';
import {
  MeResponse,
  NoContentResult,
  UpdateUserProfileRequest,
} from '@uniqkey-backend-partner/api-client';
import { useMutation, useQueryClient } from 'react-query';
import useUserProfileAPI from '../../useUserProfileAPI';
import { REACT_QUERY_CURRENT_USER_KEY } from '../index';

interface IUseUpdateCurrentUser {
  useOptimisticUpdates?: boolean;
}
// TODO: remove this after other exports will add
// eslint-disable-next-line import/prefer-default-export
export const useUpdateCurrentUser = (
  params: IUseUpdateCurrentUser = {},
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    UpdateUserProfileRequest,
    TUseMutationContext<MeResponse>
  > = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateCurrentUser } = useUserProfileAPI();
  return useMutation(
    REACT_QUERY_CURRENT_USER_KEY,
    (currentUser) => updateCurrentUser(currentUser),
    {
      onMutate: async (newCurrentUser) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(REACT_QUERY_CURRENT_USER_KEY);
        const previousValue = queryClient.getQueryData<
          MeResponse
        >(REACT_QUERY_CURRENT_USER_KEY);
        queryClient.setQueryData<MeResponse>(
          REACT_QUERY_CURRENT_USER_KEY,
          (oldCurrentUser) => ({
            ...oldCurrentUser,
            ...newCurrentUser as MeResponse,
          }),
        );
        return { previousValue: previousValue as MeResponse };
      },
      onError: (err, currentUser, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<MeResponse>(
            REACT_QUERY_CURRENT_USER_KEY,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(REACT_QUERY_CURRENT_USER_KEY);
      },
      ...options,
    },
  );
};
