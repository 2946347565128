import { memo, useCallback, useState } from 'react';
import {
  Button,
  Grid,
  PromptModal,
  Typography,
  RenderIf,
  WidgetContainer,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import ACLEnum from '../../../../../../enums/ACLEnum';
import { logException } from '../../../../../../services/sentryService';
import { useUser } from '../../../../../../contexts/UserContext';
import { useGetPartnerUserById } from '../../../../../../hooks/reactQuery';
import usePartnerUsersAPI from '../../../../../../hooks/usePartnerUsersAPI';

interface IPartnerUserResetWidgetProps {
  partnerUserId: string;
}

const PartnerUserResetWidget = (props: IPartnerUserResetWidgetProps) => {
  const { partnerUserId } = props;

  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();

  const { currentUser, userCan } = useUser();

  const {
    data: partnerUser,
    isError,
    isLoading: isPartnerUserLoading,
  } = useGetPartnerUserById(
    { partnerUserId },
  );

  const { resetMasterPassword, resetTwoFA } = usePartnerUsersAPI();

  const [isResetMasterPasswordInProgress, setIsResetMasterPasswordInProgress] = useState(false);
  const [isResetMasterPasswordModalOpen, setIsResetMasterPasswordModalOpen] = useState(false);
  const handleResetMasterPasswordModalOpen = useCallback(
    () => setIsResetMasterPasswordModalOpen(true),
    [],
  );
  const handleResetMasterPasswordModalClose = useCallback(
    () => setIsResetMasterPasswordModalOpen(false),
    [],
  );

  const [isResetTwoFAInProgress, setIsResetTwoFAInProgress] = useState(false);
  const [isResetTwoFAModalOpen, setIsResetTwoFAModalOpen] = useState(false);
  const handleResetTwoFAModalOpen = useCallback(
    () => setIsResetTwoFAModalOpen(true),
    [],
  );
  const handleResetTwoFAModalClose = useCallback(
    () => setIsResetTwoFAModalOpen(false),
    [],
  );

  const handleResetMasterPassword = useCallback(async () => {
    try {
      setIsResetMasterPasswordInProgress(true);
      await resetMasterPassword({ partnerUserId });
      handleResetMasterPasswordModalClose();
      showSuccess({ text: t('resetPartnerUserMasterPasswordModal.masterPasswordReset') });
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'PartnerUserResetWidget/handleResetMasterPassword exception',
      });
    } finally {
      setIsResetMasterPasswordInProgress(false);
    }
  }, [
    resetMasterPassword,
    partnerUserId,
    handleResetMasterPasswordModalClose,
    showError,
    showSuccess,
    t,
  ]);

  const handleResetTwoFA = useCallback(async () => {
    try {
      setIsResetTwoFAInProgress(true);
      await resetTwoFA({ partnerUserId });
      handleResetTwoFAModalClose();
      showSuccess({ text: t('resetPartnerUserTwoFAModal.twoFAReset') });
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'PartnerUserResetWidget/handleResetTwoFA exception',
      });
    } finally {
      setIsResetTwoFAInProgress(false);
    }
  }, [resetTwoFA, partnerUserId, handleResetTwoFAModalClose, showError, showSuccess, t]);

  if (!partnerUser || isError) {
    return null;
  }

  const canResetMasterPassword = userCan(ACLEnum.PartnerUserResetPassword);
  const canResetTwoFA = userCan(ACLEnum.PartnerUserReset2Fa);

  if (!canResetMasterPassword && !canResetTwoFA) {
    return null;
  }

  const { id } = currentUser ?? {};
  const { email } = partnerUser;
  const isCurrentUser = partnerUserId === id;

  return (
    <>
      <WidgetContainer
        container
        flexDirection="column"
        py={3}
        withShadow
        isLoading={isPartnerUserLoading}
      >
        <Grid item mb={3}>
          <Typography variant="h6">{t('partnerUserResetWidget.title')}</Typography>
        </Grid>
        <Grid container item gap={3}>
          <RenderIf condition={canResetMasterPassword}>
            <Grid item xs={canResetTwoFA ? true : 6}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleResetMasterPasswordModalOpen}
                disabled={isCurrentUser}
              >
                {t('partnerUserResetWidget.buttons.resetPassword')}
              </Button>
            </Grid>
          </RenderIf>
          <RenderIf condition={canResetTwoFA}>
            <Grid item xs={canResetMasterPassword ? true : 6}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleResetTwoFAModalOpen}
                disabled={isCurrentUser}
              >
                {t('partnerUserResetWidget.buttons.resetTwoFA')}
              </Button>
            </Grid>
          </RenderIf>
        </Grid>
      </WidgetContainer>
      {isResetMasterPasswordModalOpen && (
        <PromptModal
          open={isResetMasterPasswordModalOpen}
          onClose={handleResetMasterPasswordModalClose}
          onSubmit={handleResetMasterPassword}
          title={t('resetPartnerUserMasterPasswordModal.title')}
          description={t('resetPartnerUserMasterPasswordModal.description', { email })}
          approvalButtonText="common.confirm"
          isLoading={isResetMasterPasswordInProgress}
        />
      )}
      {isResetTwoFAModalOpen && (
        <PromptModal
          open={isResetTwoFAModalOpen}
          onClose={handleResetTwoFAModalClose}
          onSubmit={handleResetTwoFA}
          title={t('resetPartnerUserTwoFAModal.title')}
          description={t('resetPartnerUserTwoFAModal.description', { email })}
          approvalButtonText="common.confirm"
          isLoading={isResetTwoFAInProgress}
        />
      )}
    </>
  );
};

export default memo(PartnerUserResetWidget);
