import { memo, useMemo } from 'react';
import {
  IReactHookFormSelectProps,
  MenuItem,
  ReactHookFormSelect,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { PartnerUserRole } from '@uniqkey-backend-partner/api-client';
import { getUserRoleOptions } from '../../../../helpers/userRole';

interface IUserRoleSelectProps extends IReactHookFormSelectProps<any> {
  role: PartnerUserRole;
  label: string;
}

const UserRoleSelect = (props: IUserRoleSelectProps) => {
  const {
    role,
    name,
    control,
    label,
    fullWidth = true,
    ...restProps
  } = props;
  const { t } = useTranslations();

  const optionsElements = useMemo(() => {
    const options = getUserRoleOptions(t, role);
    if (!options.length) {
      return null;
    }
    return options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }, [role, t]);

  if (!optionsElements) {
    return null;
  }

  return (
    <ReactHookFormSelect
      name={name}
      control={control}
      fullWidth={fullWidth}
      label={label}
      {...restProps}
    >
      {optionsElements}
    </ReactHookFormSelect>
  );
};

export default memo(UserRoleSelect);
