// TODO: NEXTGEN-6419
import { memo/* , useCallback */ } from 'react';
import {
  PanelContent,
  // TODO: NEXTGEN-6419
  /* usePopper,
  useTranslations,
  useSnackbar,
  Grid,
  Tooltip,
  ExportButton,
  Divider, */
} from '@uniqkey-frontend/shared-app';
// TODO: NEXTGEN-6419
// import { PartnerLicenseResponseData } from '@uniqkey-backend-partner/api-client';
import usePartnerLicensesHistoryTable
  from '../../../../hooks/tables/usePartnerLicensesHistoryTable';
import PartnerLicensesHistoryTable from '../../../../components/tables/PartnerLicensesHistoryTable';
// TODO: NEXTGEN-6419
/* import { buildCSV } from '../../../../services/exportService';
import { logException } from '../../../../services/sentryService';
import PartnerExportLicensePopper from './components/PartnerExportLicensePopper';
import usePartnersAPI from '../../../../hooks/usePartnersAPI'; */

interface IPartnerLicensesHistoryTab {
  partnerId: string;
}

const PartnerLicensesHistoryTab = (props: IPartnerLicensesHistoryTab) => {
  const { partnerId } = props;
  // TODO: NEXTGEN-6419
  /* const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar(); */

  const {
    ...restTableProps
  } = usePartnerLicensesHistoryTable({
    persistentFilters: { partnerId },
    noDataMessageKey: 'partnerPage.licensesHistoryTab.noData',
  });
  // TODO: NEXTGEN-6419
  /* const {
    isOpen: isExportLicensePopperOpen,
    anchorEl: exportLicensePopperAnchorEl,
    setPopperIsOpen: setIsExportLicensePopperOpen,
  } = usePopper();
  const toggleIsExportLicensePopperOpen = useCallback(
    (event) => {
      setIsExportLicensePopperOpen(!isExportLicensePopperOpen, event);
    },
    [setIsExportLicensePopperOpen, isExportLicensePopperOpen],
  );

  const handleExportLicensePopperClose = useCallback(
    () => setIsExportLicensePopperOpen(false),
    [setIsExportLicensePopperOpen],
  );

  const { getPartnerExportLicenseData } = usePartnersAPI();

  const handleExportLicensePopperSubmit = useCallback(async (updatedValues) => {
    try {
      const { data: exportLicenseData } = await getPartnerExportLicenseData(
        partnerId,
        updatedValues.exportPeriod,
      );
      buildCSV<PartnerLicenseResponseData>(
        {
          exportData: exportLicenseData,
          name: 'exported_partner_licenses',
        },
      );
      showSuccess({ text: t('partnerPage.licensesHistoryTab.exportSuccessMessage') });
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'PartnerLicensesHistoryTab/handleExportLicensePopperSubmit exception',
      });
    }
  }, [getPartnerExportLicenseData, partnerId, showError, showSuccess, t]); */

  return (
    <PanelContent p={0}>
      {/* TODO: NEXTGEN-6419 */}
      {/* <Grid container p={1}>
        <Tooltip title={t('partnerExportLicensePopper.tooltip')}>
          <ExportButton
            selected={isExportLicensePopperOpen}
            onChange={toggleIsExportLicensePopperOpen}
          />
        </Tooltip>
      </Grid>
      <Divider /> */}
      <PartnerLicensesHistoryTable
        {...restTableProps}
      />
      {/* TODO: NEXTGEN-6419 */}
      {/* <PartnerExportLicensePopper
        isOpen={isExportLicensePopperOpen}
        anchorEl={exportLicensePopperAnchorEl}
        onSubmit={handleExportLicensePopperSubmit}
        onClose={handleExportLicensePopperClose}
      /> */}
    </PanelContent>
  );
};

export default memo(PartnerLicensesHistoryTab);
