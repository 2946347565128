import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import {
  bootstrapTranslationsV2,
  DEBUG_TRANSLATIONS_LOCAL_STORAGE_KEY,
} from '@uniqkey-frontend/shared-app';
import { TransifexI18next } from '@transifex/i18next';
import { tx } from '@transifex/native';
import App from './App';
import { initSentry } from './services/sentryService';
import { getBootstrapLanguage, getTranslationsInitOptions } from './services/bootstrapService';

const init = async () => {
  initSentry();

  const txBackend = new TransifexI18next({
    token: process.env.REACT_APP_TRANSIFEX_TOKEN,
  });

  tx.init({
    token: process.env.REACT_APP_TRANSIFEX_TOKEN,
  });

  const language = await getBootstrapLanguage();

  await bootstrapTranslationsV2({
    txBackend,
    language,
    initOptions: getTranslationsInitOptions(),
    showTranslationKeys: !!localStorage.getItem(DEBUG_TRANSLATIONS_LOCAL_STORAGE_KEY),
  });

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
};

init();
