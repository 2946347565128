import { TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import { GetEmployeeAccountByIdResponse } from '@uniqkey-backend-partner/api-client';
import { useQuery } from 'react-query';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';

interface IUseGetOrganizationEmployeeAccountByIdParams {
  employeeAccountId: string;
}
// TODO: remove next line after more hooks will be added
// eslint-disable-next-line import/prefer-default-export
export const useGetOrganizationEmployeeAccountById = (
  params: IUseGetOrganizationEmployeeAccountByIdParams,
  options: TUseQueryOptions<GetEmployeeAccountByIdResponse> = {},
) => {
  const { employeeAccountId } = params;
  const { getOrganizationEmployeeAccountById } = useEmployeeAccountsAPI();
  return useQuery<GetEmployeeAccountByIdResponse>(
    [ReactQueryKeyEnum.OrganizationEmployee, employeeAccountId],
    ({ signal }) => getOrganizationEmployeeAccountById(employeeAccountId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
