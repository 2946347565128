import {
  EmployeeAccountType,
  PartnerUserRole,
  PartnerType,
  EmployeeAccountStatus,
  Ownership,
} from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../enums/ACLEnum';

export const PARTNER_TYPES_TRANSLATION_KEYS = {
  [PartnerType.UniqKey]: 'partnerTypes.uniqkey',
  [PartnerType.Ambassador]: 'partnerTypes.ambassador',
  [PartnerType.Distributor]: 'partnerTypes.distributor',
  [PartnerType.Sales]: 'partnerTypes.sales',
};

export const EMPLOYEE_ACCOUNT_TYPES_TRANSLATION_KEYS = {
  [EmployeeAccountType.Admin]: 'adminRights.admin',
  [EmployeeAccountType.KeylessAdmin]: 'adminRights.promotedToAdmin',
  [EmployeeAccountType.Employee]: 'adminRights.notAdmin',
  [EmployeeAccountType.Supporter]: 'adminRights.notAdmin',
};

export const USER_ROLES_TRANSLATION_KEYS = {
  [PartnerUserRole.Admin]: 'userRoles.admin',
  [PartnerUserRole.LimitedAdmin]: 'userRoles.limitedAdmin',
  [PartnerUserRole.Partner]: 'userRoles.partner',
  [PartnerUserRole.Support]: 'userRoles.support',
  [PartnerUserRole.Audit]: 'userRoles.audit',
};

// TODO: NEXTGEN-5733
export const EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS = {
  [EmployeeAccountStatus.Active]: 'employeeStatuses.active',
  [EmployeeAccountStatus.Invited]: 'employeeStatuses.invited',
  [EmployeeAccountStatus.Archived]: 'employeeStatuses.archived',
  [EmployeeAccountStatus.Unprocessed]: 'employeeStatuses.pendingApproval',
  [EmployeeAccountStatus.Staged]: 'employeeStatuses.staged',
  [EmployeeAccountStatus.Migrated]: 'employeeStatuses.migrated',
  [EmployeeAccountStatus.MigrationInvited]: 'employeeStatuses.migrationInvited',
  [EmployeeAccountStatus.ExistingUnprocessed]: 'employeeStatuses.existingUnprocessed',
};

export const SCIM_TRANSLATION_KEYS = {
  true: 'scim.scim',
  false: 'scim.no',
};

export const OWNERSHIP_TRANSLATION_KEYS = {
  [Ownership.Employees]: 'ownership.employees',
  [Ownership.Groups]: 'ownership.groups',
  [Ownership.Unmanaged]: 'ownership.unmanaged',
};

export const MFA_TRANSLATION_KEYS = {
  true: 'mfa.2FA',
  false: 'mfa.notConfigured',
};

export const ACLS_TRANSLATION_KEYS = {
  [ACLEnum.AuditLogExport]: 'ACLs.auditLogExport',
  [ACLEnum.AuditLogView]: 'ACLs.auditLogView',
  [ACLEnum.EmployeeAccountView]: 'ACLs.employeeAccountView',
  [ACLEnum.EmployeeAccountViewAuditLogs]: 'ACLs.employeeAccountViewAuditLogs',
  [ACLEnum.EmployeeAccountViewVaultPasswords]: 'ACLs.employeeAccountViewVaultPasswords',
  [ACLEnum.GroupAddOrganizations]: 'ACLs.groupAddOrganizations',
  [ACLEnum.GroupAddUsers]: 'ACLs.groupAddUsers',
  [ACLEnum.GroupCreate]: 'ACLs.groupCreate',
  [ACLEnum.GroupDelete]: 'ACLs.groupDelete',
  [ACLEnum.GroupEdit]: 'ACLs.groupEdit',
  [ACLEnum.GroupRemoveOrganizations]: 'ACLs.groupRemoveOrganizations',
  [ACLEnum.GroupRemoveUsers]: 'ACLs.groupRemoveUsers',
  [ACLEnum.GroupView]: 'ACLs.groupView',
  [ACLEnum.OrganizationCreate]: 'ACLs.organizationCreate',
  [ACLEnum.OrganizationEdit]: 'ACLs.organizationEdit',
  [ACLEnum.OrganizationExportList]: 'ACLs.organizationExportList',
  [ACLEnum.OrganizationGoToPortal]: 'ACLs.organizationGoToPortal',
  [ACLEnum.OrganizationView]: 'ACLs.organizationView',
  [ACLEnum.OrganizationViewAdmins]: 'ACLs.organizationViewAdmins',
  [ACLEnum.OrganizationViewAuditLogs]: 'ACLs.organizationViewAuditLogs',
  [ACLEnum.OrganizationViewDomains]: 'ACLs.organizationViewDomains',
  [ACLEnum.OrganizationViewLicenseHistory]: 'ACLs.organizationViewLicenseHistory',
  [ACLEnum.PartnerCreate]: 'ACLs.partnerCreate',
  [ACLEnum.PartnerEdit]: 'ACLs.partnerEdit',
  [ACLEnum.PartnerUserCreate]: 'ACLs.partnerUserCreate',
  [ACLEnum.PartnerUserDelete]: 'ACLs.partnerUserDelete',
  [ACLEnum.PartnerUserEdit]: 'ACLs.partnerUserEdit',
  [ACLEnum.PartnerUserEditRoles]: 'ACLs.partnerUserEditRoles',
  [ACLEnum.PartnerUserReset2Fa]: 'ACLs.partnerUserReset2FA',
  [ACLEnum.PartnerUserResetPassword]: 'ACLs.partnerUserResetPassword',
  [ACLEnum.PartnerUserView]: 'ACLs.partnerUserView',
  [ACLEnum.PartnerView]: 'ACLs.partnerView',
  [ACLEnum.PartnerViewLicenseHistory]: 'ACLs.partnerViewLicenseHistory',
  [ACLEnum.PartnerViewOrganizations]: 'ACLs.partnerViewOrganizations',
  [ACLEnum.PermissionView]: 'ACLs.permissionView',
};
