import { memo } from 'react';
import {
  ScrollablePanelContent,
  Box,
} from '@uniqkey-frontend/shared-app';
import PartnerDetailsWidget from './components/PartnerDetailsWidget';
import ContactPersonDetailsWidget from './components/ContactPersonDetailsWidget';

interface IPartnerDetailsTabProps {
  partnerId: string;
}

const PartnerDetailsTab = (props: IPartnerDetailsTabProps) => {
  const { partnerId } = props;

  return (
    <ScrollablePanelContent p={3} pt={1}>
      <PartnerDetailsWidget partnerId={partnerId} />
      <Box mt={3} />
      <ContactPersonDetailsWidget partnerId={partnerId} />
    </ScrollablePanelContent>
  );
};

export default memo(PartnerDetailsTab);
