import { Grid, Panel } from '@uniqkey-frontend/shared-app';
import useRolesTable from '../../hooks/tables/useRolesTable';
import RolesTable from '../../components/tables/RolesTable';

const RolesPage = () => {
  const tableProps = useRolesTable({
    noDataMessageKey: 'table.noData.default',
  });

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item xs>
        <Panel>
          <RolesTable {...tableProps} />
        </Panel>
      </Grid>
    </Grid>
  );
};

export default RolesPage;
