import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  GetOrganizationsResponseModel,
  OrganizationOrderProperty,
} from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  IGetOrganizationsParams,
  TGetOrganizationsMethod,
} from '../../usePartnerOrganizationsAPI/interfaces';
import { IOrganizationsTableRow } from '../../../components/tables/OrganizationsTable';
import usePartnerOrganizationsAPI from '../../usePartnerOrganizationsAPI';
import {
  IOrganizationsListFilterSubmitResult,
} from '../../../pages/OrganizationsPage/components/OrganizationsListFilter';
import { isCountryValid } from '../../../helpers/countries';

interface IPersistentFilters extends Pick<IGetOrganizationsParams, 'partnerId'> {}

export interface IUseOrganizationsTableProps {
  persistentFilters?: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationsTableReturn extends
  Omit<IUseTableReturn<IOrganizationsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseTableFetchReturn<GetOrganizationsResponseModel>, 'data'>,
  IUseFilterButtonReturn
{
  organizations: IUseTableFetchReturn<GetOrganizationsResponseModel>['data'];
  selectedOrganizations: IUseTableReturn<IOrganizationsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetOrganizationsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetOrganizationsParams['searchQuery']>>;
  filterValues: IOrganizationsListFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IOrganizationsListFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationsParams,
    'page' | 'pageLength' | 'partnerId' | 'orderPropertyName' | 'isDescending' | 'cancelToken'
  >,
  Pick<IUseTableReturn<IOrganizationsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetOrganizationsParams['page'];
  perPage: IGetOrganizationsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: OrganizationOrderProperty },
  ),
  columnOrderDirection: true,
  countryFilter: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.STRING,
    { isValid: isCountryValid },
  ),
};

const useOrganizationsTable = (
  props: IUseOrganizationsTableProps,
): IUseOrganizationsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getOrganizations } = usePartnerOrganizationsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    countryFilter: initialCountryFilter,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetOrganizationsParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IOrganizationsListFilterSubmitResult
  >({
    countryFilter: initialCountryFilter,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedOrganizations,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      countryFilter: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: organizations, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetOrganizationsParams,
    TGetOrganizationsMethod,
    GetOrganizationsResponseModel
  >({
    request: getOrganizations,
    params: {
      page: activePage,
      pageLength: perPage,
      countryFilter: filterValues.countryFilter,
      searchQuery,
      orderPropertyName: OrganizationOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof OrganizationOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    countryFilter: filterValues.countryFilter,
  });

  return {
    organizations,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedOrganizations,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationsTable;
