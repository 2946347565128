import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  EmployeeAccountsOrderProperty,
  EmployeeAccountStatus,
  EmployeeAccountType,
  GetEmployeeAccountsResponseModel,
} from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  IGetOrganizationEmployeeAccountsParams,
  TGetOrganizationEmployeeAccountsMethod,
} from '../../useEmployeeAccountsAPI/interfaces';
// eslint-disable-next-line max-len
import { IOrganizationEmployeesTabFilterSubmitResult } from '../../../pages/OrganizationPage/components/OrganizationEmployeesTab/components/OrganizationEmployeesTabFilter';
import {
  IOrganizationEmployeesTableRow,
} from '../../../components/tables/OrganizationEmployeesTable';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';

interface IPersistentFilters extends Pick<
  IGetOrganizationEmployeeAccountsParams, 'organizationId'
> {}

export interface IUseOrganizationEmployeesTableProps {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationEmployeesTableReturn extends
  Omit<IUseTableReturn<IOrganizationEmployeesTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseTableFetchReturn<GetEmployeeAccountsResponseModel>, 'data'>,
  IUseFilterButtonReturn
{
  employees: IUseTableFetchReturn<GetEmployeeAccountsResponseModel>['data'];
  selectedEmployees: IUseTableReturn<IOrganizationEmployeesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetOrganizationEmployeeAccountsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetOrganizationEmployeeAccountsParams['searchQuery']>>;
  filterValues: IOrganizationEmployeesTabFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IOrganizationEmployeesTabFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationEmployeeAccountsParams,
    'page' | 'pageLength' | 'organizationId' | 'orderPropertyName' | 'isDescending' | 'cancelToken'
  >,
  Pick<IUseTableReturn<IOrganizationEmployeesTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetOrganizationEmployeeAccountsParams['page'];
  perPage: IGetOrganizationEmployeeAccountsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: EmployeeAccountsOrderProperty },
  ),
  columnOrderDirection: true,
  typeFilter: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: EmployeeAccountType },
  ),
  statusFilter: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: EmployeeAccountStatus },
  ),
};

const useOrganizationEmployeesTable = (
  props: IUseOrganizationEmployeesTableProps,
): IUseOrganizationEmployeesTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getOrganizationEmployeeAccounts } = useEmployeeAccountsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    typeFilter: initialTypeFilter,
    statusFilter: initialStatusFilter,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetOrganizationEmployeeAccountsParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IOrganizationEmployeesTabFilterSubmitResult
  >({
    typeFilter: initialTypeFilter,
    statusFilter: initialStatusFilter,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedEmployees,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationEmployeesTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      typeFilter: undefined,
      statusFilter: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: employees, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetOrganizationEmployeeAccountsParams,
    TGetOrganizationEmployeeAccountsMethod,
    GetEmployeeAccountsResponseModel
  >({
    request: getOrganizationEmployeeAccounts,
    params: {
      page: activePage,
      pageLength: perPage,
      typeFilter: filterValues.typeFilter,
      statusFilter: filterValues.statusFilter,
      searchQuery,
      orderPropertyName: EmployeeAccountsOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof EmployeeAccountsOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    typeFilter: filterValues.typeFilter,
    statusFilter: filterValues.statusFilter,
  });

  return {
    employees,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedEmployees,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useOrganizationEmployeesTable;
