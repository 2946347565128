import { memo } from 'react';
import { PanelContent } from '@uniqkey-frontend/shared-app';
import OrganizationEmployeeAuditLogsTable from
  '../../../../components/tables/OrganizationEmployeeAuditLogsTable';
import useOrganizationEmployeeAuditLogsTable from
  '../../../../hooks/tables/useOrganizationEmployeeAuditLogsTable';

interface IOrganizationEmployeeAuditLogsTab {
  employeeAccountId: string;
}

const OrganizationEmployeeAuditLogsTab = (props: IOrganizationEmployeeAuditLogsTab) => {
  const { employeeAccountId } = props;

  const {
    ...restTableProps
  } = useOrganizationEmployeeAuditLogsTable({
    persistentFilters: { employeeAccountId },
    noDataMessageKey: 'table.noData.default',
  });

  return (
    <PanelContent p={0}>
      <OrganizationEmployeeAuditLogsTable
        {...restTableProps}
      />
    </PanelContent>
  );
};

export default memo(OrganizationEmployeeAuditLogsTab);
