import { useCallback } from 'react';
import {
  PanelContent,
  Grid,
  Divider,
  FilterButton,
  Tooltip,
  usePopper,
  useTranslations,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import useAuditLogsTable from '../../../../hooks/tables/useAuditLogsTable';
import AuditLogsTable from '../../../../components/tables/AuditLogsTable';
import AuditLogsListFilter, {
  IAuditLogsListFilterSubmitResult,
} from './components/AuditLogsListFilter';

const AuditLogsTab = () => {
  const { t } = useTranslations();

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const {
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps
  } = useAuditLogsTable({
    noDataMessageKey: 'table.noData.default',
  });

  const handleFilterSubmit = useCallback((updatedValues: IAuditLogsListFilterSubmitResult) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <AuditLogsTable {...restTableProps} />
      <AuditLogsListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
    </PanelContent>
  );
};

export default AuditLogsTab;
