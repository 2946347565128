import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  LoginStepOneRequest,
  LoginStepOneResponse,
  LoginStepTwoRequest,
  LoginStepTwoResponse,
  MeResponse,
  AccessRightsResponse,
} from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useAuthAPI = () => {
  const { authAPIClient } = useApiClients();

  const loginStep1 = useCallback(
    (
      params: LoginStepOneRequest,
      options?: AxiosRequestConfig,
    ): Promise<LoginStepOneResponse> => authAPIClient
      .apiV1LoginLoginStepOnePost(params, options)
      .then(dataExtractor<LoginStepOneResponse>()),
    [authAPIClient],
  );

  const loginStep2 = useCallback(
    (
      params: LoginStepTwoRequest,
      options?: AxiosRequestConfig,
    ): Promise<LoginStepTwoResponse> => authAPIClient
      .apiV1LoginLoginStepTwoPost(params, options)
      .then(dataExtractor<LoginStepTwoResponse>()),
    [authAPIClient],
  );

  const fetchMe = useCallback(
    (options?: AxiosRequestConfig): Promise<MeResponse> => authAPIClient
      .apiV1LoginMeGet(options)
      .then(dataExtractor<MeResponse>()),
    [authAPIClient],
  );

  const getCurrentUserACLs = useCallback(
    (options?: AxiosRequestConfig): Promise<AccessRightsResponse> => authAPIClient
      .apiV1LoginAccessRightsGet(options)
      .then(dataExtractor<AccessRightsResponse>()),
    [authAPIClient],
  );

  return ({
    loginStep1,
    loginStep2,
    fetchMe,
    getCurrentUserACLs,
  });
};

export default useAuthAPI;
