import axios from 'axios';
import PubSub from 'pubsub-js';
import { LoginApi, RefreshTokensResponse } from '@uniqkey-backend-partner/api-client';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import config from '../../config';
import { dataExtractor } from '../../helpers/apiClients';
import {
  getJWTToken,
  setJWTToken,
  getRefreshToken,
  setRefreshToken,
  logout,
} from '../authService';

const PARTNER_API_BASE_URL = config.getPartnerApiUrl();

const axiosInstanceWithoutAuthRefresh = axios.create({
  baseURL: PARTNER_API_BASE_URL,
  timeout: config.requestTimeout,
});

const authClient = new LoginApi(
  undefined,
  PARTNER_API_BASE_URL,
  axiosInstanceWithoutAuthRefresh,
);

const refreshTokens = async () => {
  const jwtToken = getJWTToken();
  if (!jwtToken) throw new Error('no jwt token found');

  const refreshToken = getRefreshToken();
  if (!refreshToken) throw new Error('no refresh token found');

  const {
    jwtToken: refreshedJwtToken,
    refreshToken: refreshedRefreshToken,
  } = await authClient.apiV1LoginRefreshTokensPost({
    jwtToken,
    refreshToken,
  }).then(dataExtractor<RefreshTokensResponse>());

  setJWTToken(refreshedJwtToken as string);
  setRefreshToken(refreshedRefreshToken as string);

  PubSub.publish(PubSubEventEnum.TOKENS_REFRESHED);
};

export const triggerTokensRefresh = () => {
  PubSub.publish(PubSubEventEnum.TRIGGER_TOKENS_REFRESH);
};

export const listenRefreshTokensEvents = () => {
  PubSub.subscribe(PubSubEventEnum.TRIGGER_TOKENS_REFRESH, async () => {
    try {
      await refreshTokens();
    } catch (e) {
      logout({ showMessage: true });
    }
  });
};

export default refreshTokens;
