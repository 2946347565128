import { useEffect, useState } from 'react';
import { authenticator } from 'otplib';

interface IUseTwoFactorSecretGeneratorReturn {
  twoFactorSecret: string;
}

const TWO_FA_SECRET_REFRESH = 30 * 60 * 1000;

const NUMBER_OF_BYTES = 20;

const generateSecret = () => authenticator.generateSecret(NUMBER_OF_BYTES);

const useTwoFactorSecretGenerator = (): IUseTwoFactorSecretGeneratorReturn => {
  const [twoFactorSecret, setTwoFactorSecret] = useState(generateSecret);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTwoFactorSecret(generateSecret());
    }, TWO_FA_SECRET_REFRESH);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return { twoFactorSecret };
};

export default useTwoFactorSecretGenerator;
