import { LoginApi, MeResponse } from '@uniqkey-backend-partner/api-client';
import { axiosInstanceWithoutLogout } from '../../axios';
import config from '../../config';
import { dataExtractor } from '../../helpers/apiClients';

const API_URL = config.getPartnerApiUrl();

const getAuthAPIClient = () => new LoginApi(
  undefined,
  API_URL,
  axiosInstanceWithoutLogout,
);
// eslint-disable-next-line import/prefer-default-export
export const getMeWithoutLogout = async () => getAuthAPIClient()
  .apiV1LoginMeGet()
  .then(dataExtractor<MeResponse>());
