import { memo } from 'react';
import { PanelContent } from '@uniqkey-frontend/shared-app';
import usePartnersTable from '../../../../hooks/tables/usePartnersTable';
import PartnersTable from '../../../../components/tables/PartnersTable';

interface IPartnerPartnersTabProps {
  partnerId: string;
  partnerId1?: string;
  partnerId2?: string;
}

const PartnerPartnersTab = (props: IPartnerPartnersTabProps) => {
  const {
    partnerId, partnerId1, partnerId2,
  } = props;

  const tableProps = usePartnersTable({
    persistentFilters: { partnerId },
    noDataMessageKey: 'table.noData.default',
  });

  return (
    <PanelContent p={0}>
      <PartnersTable
        partnerId={partnerId}
        partnerId1={partnerId1}
        partnerId2={partnerId2}
        {...tableProps}
      />
    </PanelContent>
  );
};

export default memo(PartnerPartnersTab);
