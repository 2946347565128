import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { loadCustomScrollbarPlugins, loadStyles } from '@uniqkey-frontend/shared-app';
import Router from './Router';
import APIClientsProviderContext from './contexts/APIClientsContext/provider';
import UserProviderContext from './contexts/UserContext/provider';
import { listenRefreshTokensEvents } from './services/tokensManager';

// @ts-ignore
import('./index.css');

loadStyles({
  themeColors: true,
  dateRangePicker: true,
  snackbar: true,
  customScrollbar: true,
});

loadCustomScrollbarPlugins();

listenRefreshTokensEvents();

const ReactQueryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={ReactQueryClient}>
    <APIClientsProviderContext>
      <UserProviderContext>
        <Suspense fallback={null}>
          <Router />
        </Suspense>
        <ReactQueryDevtools />
      </UserProviderContext>
    </APIClientsProviderContext>
  </QueryClientProvider>
);
export default App;
