import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  TUseMutationContext,
  TUseMutationOptions,
  TUseQueryOptions,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerByIdResponse,
  NoContentResult,
  UpdatePartnerRequest,
} from '@uniqkey-backend-partner/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import usePartnersAPI from '../../usePartnersAPI';

interface IUseGetPartnerByIdParams {
  partnerId: string;
}

export const useGetPartnerById = (
  params: IUseGetPartnerByIdParams,
  options: TUseQueryOptions<GetPartnerByIdResponse> = {},
) => {
  const { partnerId } = params;
  const { getPartnerById } = usePartnersAPI();
  return useQuery<GetPartnerByIdResponse>(
    [ReactQueryKeyEnum.Partner, partnerId],
    ({ signal }) => getPartnerById(partnerId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdatePartnerParams {
  partnerId: string;
  useOptimisticUpdates?: boolean;
}

export const useUpdatePartner = (
  params: IUseUpdatePartnerParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    Omit<UpdatePartnerRequest, 'partnerId'>,
    TUseMutationContext<GetPartnerByIdResponse>
  > = {},
) => {
  const { partnerId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updatePartner } = usePartnersAPI();
  const mutationKey = [ReactQueryKeyEnum.Partner, partnerId];
  return useMutation(
    mutationKey,
    (partner) => updatePartner(
      { ...partner, partnerId },
    ),
    {
      onMutate: async (newPartner) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<
          GetPartnerByIdResponse
        >(mutationKey);
        queryClient.setQueryData<GetPartnerByIdResponse>(
          mutationKey,
          (oldPartner) => ({
            ...oldPartner,
            ...newPartner as GetPartnerByIdResponse,
          }),
        );
        return { previousValue: previousValue as GetPartnerByIdResponse };
      },
      onError: (err, partner, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetPartnerByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(mutationKey);
      },
      ...options,
    },
  );
};
