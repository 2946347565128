import { ReactNode } from 'react';
import { Grid } from '@uniqkey-frontend/shared-app';
import Side from './Side';

interface AuthorizedLayoutProps {
  children: ReactNode,
}

const AuthorizedLayout = (props: AuthorizedLayoutProps) => {
  const { children } = props;
  return (
    <Grid container className="min-height-100-vh" columnSpacing={2} p={2}>
      <Grid container flexDirection="column" item xs={3} xl={2} md={3} lg={2}>
        <Side />
      </Grid>
      <Grid item xs={9} xl={10} md={9} lg={10}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthorizedLayout;
