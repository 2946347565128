import { memo, useCallback } from 'react';
import {
  PanelContent,
  Grid,
  Divider,
  SearchableTextField,
  useTranslations,
  FilterButton,
  Tooltip,
  usePopper,
  ISearchableTextFieldProps,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import OrganizationEmployeeLoginsTable
  from '../../../../components/tables/OrganizationEmployeeLoginsTable';
import useOrganizationEmployeeLoginsTable
  from '../../../../hooks/tables/useOrganizationEmployeeLoginsTable';
import OrganizationEmployeeLoginsTabFilter, {
  IOrganizationEmployeeLoginsTabFilterSubmitResult,
} from './components/OrganizationEmployeeLoginsTabFilter';

interface IOrganizationEmployeeLoginsTabProps {
  employeeAccountId: string;
}

const OrganizationEmployeeLoginsTab = (props: IOrganizationEmployeeLoginsTabProps) => {
  const { employeeAccountId } = props;
  const { t } = useTranslations();

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );

  const {
    selectedLogins,
    searchQuery,
    setSearchQuery,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    filterValues,
    resetActivePage,
    ...restTableProps
  } = useOrganizationEmployeeLoginsTable({
    persistentFilters: { employeeAccountId },
    noDataMessageKey: 'table.noData.default',
  });

  const handleFilterSubmit = useCallback((
    updatedValues: IOrganizationEmployeeLoginsTabFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const handleFilterClose = useCallback(
    () => setIsFilterOpen(false),
    [setIsFilterOpen],
  );

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <OrganizationEmployeeLoginsTable
        selectedLogins={selectedLogins}
        {...restTableProps}
      />
      <OrganizationEmployeeLoginsTabFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
    </PanelContent>
  );
};

export default memo(OrganizationEmployeeLoginsTab);
