import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import OrganizationEmployeePageBreadcrumbs from './components/OrganizationEmployeePageBreadcrumbs';

const OrganizationEmployeePage = () => {
  const params = useParams();
  const { organizationId, employeeAccountId } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <OrganizationEmployeePageBreadcrumbs
          employeeAccountId={employeeAccountId!}
          organizationId={organizationId!}
        />
      </Grid>
      <Grid item xs>
        <Outlet context={{ employeeAccountId, organizationId }} />
      </Grid>
    </Grid>
  );
};

export default OrganizationEmployeePage;
