import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  CreatePartnerUserRequest,
  GetPartnerUsersResponse,
  GetPartnerUserByIdResponse,
  NoContentResult,
  DeletePartnerUsersBulkRequest,
  DeletePartnerUsersBulkResponse,
  UpdatePartnerUserRequest,
  ChangeRoleRequest,
  ResetMasterPasswordRequest,
  ResetTwoFactorRequest,
} from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';
import { TGetPartnerUsersMethod } from './interfaces';

const usePartnerUsersAPI = () => {
  const { partnerUsersAPIClient } = useApiClients();

  const getPartnerUsers = useCallback<TGetPartnerUsersMethod>(
    (params) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        roleFilter = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
        cancelToken = undefined,
      } = params;
      return partnerUsersAPIClient.apiV1PartnerUsersGet(
        page,
        pageLength,
        partnerId,
        roleFilter,
        searchQuery,
        orderPropertyName,
        isDescending,
        { cancelToken },
      ).then(dataExtractor<GetPartnerUsersResponse>());
    },
    [partnerUsersAPIClient],
  );

  const createPartnerUser = useCallback(
    (params: CreatePartnerUserRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => partnerUsersAPIClient
      .apiV1PartnerUsersPost(params, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerUsersAPIClient],
  );

  const getPartnerUserById = useCallback(
    (partnerUserId: string, options?: AxiosRequestConfig)
      : Promise<GetPartnerUserByIdResponse> => partnerUsersAPIClient
      .apiV1PartnerUsersPartnerUserIdGet(partnerUserId, options)
      .then(dataExtractor<GetPartnerUserByIdResponse>()),
    [partnerUsersAPIClient],
  );

  const deletePartnerUsers = useCallback(
    (deletePartnerUsersBulkRequest: DeletePartnerUsersBulkRequest, options?: AxiosRequestConfig)
      :Promise<DeletePartnerUsersBulkResponse> => partnerUsersAPIClient
      .apiV1PartnerUsersBulkDeleteDelete(deletePartnerUsersBulkRequest, options)
      .then(dataExtractor<DeletePartnerUsersBulkResponse>()),
    [partnerUsersAPIClient],
  );

  const updatePartnerUser = useCallback(
    (updatePartnerUserRequest: UpdatePartnerUserRequest, options?: AxiosRequestConfig)
      :Promise<NoContentResult> => partnerUsersAPIClient
      .apiV1PartnerUsersPut(updatePartnerUserRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerUsersAPIClient],
  );

  const updatePartnerUserRole = useCallback(
    (changeRoleRequest: ChangeRoleRequest, options?: AxiosRequestConfig)
      :Promise<NoContentResult> => partnerUsersAPIClient
      .apiV1PartnerUsersChangeRolePut(changeRoleRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerUsersAPIClient],
  );

  const resetMasterPassword = useCallback(
    (resetMasterPasswordRequest?: ResetMasterPasswordRequest, options?: AxiosRequestConfig)
      :Promise<NoContentResult> => partnerUsersAPIClient
      .apiV1PartnerUsersResetMasterPasswordPost(resetMasterPasswordRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerUsersAPIClient],
  );

  const resetTwoFA = useCallback(
    (resetTwoFactorRequest?: ResetTwoFactorRequest, options?: AxiosRequestConfig)
      :Promise<NoContentResult> => partnerUsersAPIClient
      .apiV1PartnerUsersResetTwoFactorPost(resetTwoFactorRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [partnerUsersAPIClient],
  );

  return ({
    getPartnerUsers,
    createPartnerUser,
    getPartnerUserById,
    deletePartnerUsers,
    updatePartnerUser,
    updatePartnerUserRole,
    resetMasterPassword,
    resetTwoFA,
  });
};

export default usePartnerUsersAPI;
