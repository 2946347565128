import { useCallback } from 'react';
import {
  useSnackbar,
  useTable,
  useTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { GetAccessRightsResponseModel } from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  IGetACLsByRoleParams,
  TGetACLsByRoleMethod,
} from '../../usePermissionsAPI/interfaces';
import { IRoleTableRow } from '../../../components/tables/RoleTable';
import usePermissionsAPI from '../../usePermissionsAPI';

interface IPersistentFilters extends Pick<IGetACLsByRoleParams, 'role'> {}

export interface IUseRoleTableProps {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseRoleTableReturn extends
  Omit<IUseTableReturn<IRoleTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetAccessRightsResponseModel>, 'data'>
{
  roleACLs: IUseTableFetchReturn<GetAccessRightsResponseModel>['data'];
  selectedRoleACLs: IUseTableReturn<IRoleTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetACLsByRoleParams, 'page' | 'pageLength' | 'role' | 'cancelToken'>
{
  activePage: IGetACLsByRoleParams['page'];
  perPage: IGetACLsByRoleParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
};

const useRoleTable = (
  props: IUseRoleTableProps,
): IUseRoleTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getACLsByRole } = usePermissionsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    selectedRows: selectedRoleACLs,
    resetTableMethods,
    ...restTableProps
  } = useTable<IRoleTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: roleACLs, isLoading, isFetchedAfterMount, total, refetch,
  } = useTableFetch<
    IGetACLsByRoleParams,
    TGetACLsByRoleMethod,
    GetAccessRightsResponseModel
  >({
    request: getACLsByRole,
    params: {
      page: activePage,
      pageLength: perPage,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
  });

  return {
    roleACLs,
    isLoading,
    total,
    refetch,
    activePage,
    perPage,
    selectedRoleACLs,
    localization,
    ...restTableProps,
  };
};

export default useRoleTable;
