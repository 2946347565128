import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import OrganizationPageBreadcrumbs from './components/OrganizationPageBreadcrumbs';

const OrganizationPage = () => {
  const params = useParams();
  const { organizationId } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <OrganizationPageBreadcrumbs organizationId={organizationId!} />
      </Grid>
      <Grid item xs minHeight={0}>
        <Outlet context={{ organizationId }} />
      </Grid>
    </Grid>
  );
};

export default OrganizationPage;
