import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import { useGetPartnerUserById } from '../../../../hooks/reactQuery';
import PartnerUserDetailsTab from '../PartnerUserDetailsTab';

export enum PartnerUserModuleTabEnum {
  Details = 'details',
}
interface IPartnerUserModuleProps {
  activeTab: PartnerUserModuleTabEnum;
}
interface IPartnerUserModuleContext {
  partnerUserId: string;
}

const PartnerUserModule = (props: IPartnerUserModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const { partnerUserId } = useOutletContext<IPartnerUserModuleContext>();
  const navigate = useNavigate();

  const { isLoading } = useGetPartnerUserById(
    { partnerUserId },
  );

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: PartnerUserModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.PartnerUsers}/${partnerUserId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [partnerUserId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === PartnerUserModuleTabEnum.Details;

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('partnerUserPage.tabs.userDetails')}
            value={PartnerUserModuleTabEnum.Details}
            onClick={handleTabClick}
          />
        </Tabs>
      </PanelHeader>
      <RenderIf condition={activeTab === PartnerUserModuleTabEnum.Details}>
        <PartnerUserDetailsTab partnerUserId={partnerUserId} />
      </RenderIf>
    </Panel>
  );
};

export default memo(PartnerUserModule);
