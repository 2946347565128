import { useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetPartnerUserById } from '../../../../hooks/reactQuery';

interface IPartnerUserPageBreadcrumbsProps {
  partnerUserId: string;
}

const PartnerUserPageBreadcrumbs = (props: IPartnerUserPageBreadcrumbsProps) => {
  const { partnerUserId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();

  const { data: partnerUser, isLoading } = useGetPartnerUserById(
    { partnerUserId },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
        navigate(PageRouteEnum.PartnerUsers);
      },
    },
  );

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !partnerUser) {
      return [];
    }
    return [
      {
        title: t('navigation.partnerUsers'),
        onClick: () => navigate(PageRouteEnum.PartnerUsers),
      },
      { title: partnerUser.name },
    ];
  }, [isLoading, partnerUser, navigate, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(PartnerUserPageBreadcrumbs);
