import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GetOrganizationByIdResponse,
  NoContentResult,
  UpdatePartnerOrganizationRequest,
} from '@uniqkey-backend-partner/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import usePartnerOrganizationsAPI from '../../usePartnerOrganizationsAPI';

interface IUseGetOrganizationByIdParams {
  organizationId: string;
}

export const useGetOrganizationById = (
  params: IUseGetOrganizationByIdParams,
  options: TUseQueryOptions<GetOrganizationByIdResponse> = {},
) => {
  const { organizationId } = params;
  const { getOrganizationById } = usePartnerOrganizationsAPI();
  return useQuery<GetOrganizationByIdResponse>(
    [ReactQueryKeyEnum.Organization, organizationId],
    ({ signal }) => getOrganizationById(organizationId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdateOrganizationParams {
  organizationId: string;
  partnerId: string;
  useOptimisticUpdates?: boolean;
}

export const useUpdateOrganization = (
  params: IUseUpdateOrganizationParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    Omit<UpdatePartnerOrganizationRequest, 'organizationId' | 'partnerId'>,
    TUseMutationContext<GetOrganizationByIdResponse>
  > = {},
) => {
  const { organizationId, partnerId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateOrganization } = usePartnerOrganizationsAPI();
  const mutationKey = [ReactQueryKeyEnum.Organization, organizationId];
  return useMutation(
    mutationKey,
    (organization) => updateOrganization(
      { ...organization, organizationId, partnerId },
    ),
    {
      onMutate: async (newOrganization) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<
          GetOrganizationByIdResponse
        >(mutationKey);
        queryClient.setQueryData<GetOrganizationByIdResponse>(
          mutationKey,
          (oldOrganization) => ({
            ...oldOrganization,
            ...newOrganization as GetOrganizationByIdResponse,
          }),
        );
        return { previousValue: previousValue as GetOrganizationByIdResponse };
      },
      onError: (err, organization, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetOrganizationByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(mutationKey);
      },
      ...options,
    },
  );
};
