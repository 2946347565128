import { useCallback, useState } from 'react';
import {
  useTranslations,
  NavigationPanel,
  NavigationItem,
  OrganizationIcon,
  LogoutIcon,
  Box,
  ScrollableBox,
  InviteUserIcon,
  ComputerIcon,
  ProfileIcon,
  RenderIf,
  RolesIcon,
  // TODO: NEXTGEN-5955
  // GroupIcon,
  PromptDialog,
  INavigationItemProps,
} from '@uniqkey-frontend/shared-app';
import {
  useLocation,
  useNavigate,
  useMatch,
} from 'react-router-dom';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import ACLEnum from '../../../enums/ACLEnum';
import { useUser } from '../../../contexts/UserContext';
import { publishTablePreservationReset } from '../../../helpers/tablePreservation/reset';
import { logout } from '../../../services/authService';
import { AuditLogsModuleTabEnum } from '../../../pages/AuditLogsPage/components/AuditLogsModule';

const LIST_ITEM_TOP_MARGIN = 8;
const LIST_ITEM_ELEMENT_HEIGHT = 48;

const MAIN_MENU_ITEMS_SX = {
  minHeight: (LIST_ITEM_TOP_MARGIN + LIST_ITEM_ELEMENT_HEIGHT) * 2.5,
};

const handleLogout = () => logout();

const PATHS_WITH_TABS = {
  Audit: `${PageRouteEnum.Audit}/${AuditLogsModuleTabEnum.AuditLogs}`,
};

const Side = () => {
  const { userCan } = useUser();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const handleLogoutModalOpen = useCallback(() => setIsLogoutModalOpen(true), []);
  const handleLogoutModalClose = useCallback(() => setIsLogoutModalOpen(false), []);

  const isPartnerPage = !!useMatch(PageRouteEnum.Partner);
  const isPartnerPartnerUserPage = !!useMatch(PageRouteEnum.PartnerPartnerUser);
  const isOrganizationPage = !!useMatch(PageRouteEnum.Organization);
  const isOrganizationEmployeePage = !!useMatch(PageRouteEnum.OrganizationEmployee);
  const isPartnerOrganizationEmployeePage = !!useMatch(PageRouteEnum.PartnersOrganizationEmployee);
  const isPartnerUserPage = !!useMatch(PageRouteEnum.PartnerUser);
  // TODO: NEXTGEN-5955
  // const isGroupPage = !!useMatch(PageRouteEnum.Group);
  const isAuditTab = !!useMatch(`${PageRouteEnum.Audit}/*`);
  const isRolePage = !!useMatch(PageRouteEnum.Role);

  const handleMenuItemClick = useCallback<INavigationItemProps['onClick']>((nextPathname) => {
    if (pathname !== nextPathname) {
      navigate(nextPathname as string);
      return;
    }
    navigate(nextPathname, { replace: true });
    publishTablePreservationReset();
  }, [pathname, navigate]);

  return (
    <NavigationPanel container item xs>
      <ScrollableBox sx={MAIN_MENU_ITEMS_SX}>
        {/* Box below is needed for proper work of OverlayScrollbars */}
        <Box>
          <RenderIf condition={userCan(ACLEnum.PartnerView)}>
            <NavigationItem
              pathname={PageRouteEnum.Partners}
              isActive={isPartnerPage || pathname === PageRouteEnum.Partners
              || isPartnerPartnerUserPage || isPartnerOrganizationEmployeePage}
              text={t('navigation.partners')}
              icon={<InviteUserIcon />}
              onClick={handleMenuItemClick}
            />
          </RenderIf>
          <RenderIf condition={userCan(ACLEnum.OrganizationView)}>
            <NavigationItem
              pathname={PageRouteEnum.Organizations}
              isActive={isOrganizationPage || isOrganizationEmployeePage
              || pathname === PageRouteEnum.Organizations}
              text={t('navigation.organizations')}
              icon={<OrganizationIcon />}
              onClick={handleMenuItemClick}
            />
          </RenderIf>
          <RenderIf condition={userCan(ACLEnum.PartnerUserView)}>
            <NavigationItem
              pathname={PageRouteEnum.PartnerUsers}
              isActive={isPartnerUserPage || pathname === PageRouteEnum.PartnerUsers}
              text={t('navigation.partnerUsers')}
              icon={<ProfileIcon />}
              onClick={handleMenuItemClick}
            />
          </RenderIf>
          {/* TODO: NEXTGEN-5955
          <RenderIf condition={userCan(ACLEnum.GroupView)}>
            <NavigationItem
              pathname={PageRouteEnum.Groups}
              isActive={isGroupPage || pathname === PageRouteEnum.Groups}
              text={t('navigation.groups')}
              icon={<GroupIcon />}
              onClick={handleMenuItemClick}
            />
          </RenderIf>
          */}
          <RenderIf condition={userCan(ACLEnum.AuditLogView)}>
            <NavigationItem
              pathname={PATHS_WITH_TABS.Audit}
              isActive={isAuditTab}
              text={t('navigation.auditLogs')}
              icon={<ComputerIcon />}
              onClick={handleMenuItemClick}
            />
          </RenderIf>
          <RenderIf condition={userCan(ACLEnum.PermissionView)}>
            <NavigationItem
              pathname={PageRouteEnum.Roles}
              isActive={isRolePage || pathname === PageRouteEnum.Roles}
              text={t('navigation.roles')}
              icon={<RolesIcon />}
              onClick={handleMenuItemClick}
            />
          </RenderIf>
        </Box>
      </ScrollableBox>
      <Box>
        <NavigationItem
          pathname={PageRouteEnum.Profile}
          isActive={pathname === PageRouteEnum.Profile}
          text={t('navigation.profile')}
          icon={<ProfileIcon />}
          onClick={handleMenuItemClick}
        />
        <NavigationItem
          text={t('navigation.logout')}
          icon={<LogoutIcon />}
          onClick={handleLogoutModalOpen}
        />
      </Box>
      {isLogoutModalOpen && (
        <PromptDialog
          open={isLogoutModalOpen}
          onClose={handleLogoutModalClose}
          onSubmit={handleLogout}
          title={t('logoutModal.title')}
          approvalButtonText={t('common.confirm')}
          rejectionButtonText={t('common.cancel')}
        />
      )}
    </NavigationPanel>
  );
};

export default Side;
