import { useMemo, memo } from 'react';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
  formatDate,
  DateTimeFormatEnum,
  getMonthName,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerHistoricLicenseDataResponseModel,
} from '@uniqkey-backend-partner/api-client';

export interface IPartnerLicensesHistoryTableRow extends
  GetPartnerHistoricLicenseDataResponseModel {
  processedDate: string | null;
  month: string | null;
}

interface IPartnerLicensesHistoryTableProps extends
  Omit<IUseTableReturn<IPartnerLicensesHistoryTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseTableFetchReturn<GetPartnerHistoricLicenseDataResponseModel>, 'data' | 'refetch'>
{
  partnerLicensesHistory: IUseTableFetchReturn<GetPartnerHistoricLicenseDataResponseModel>['data'];
  selectedPartnerLicensesHistory: IUseTableReturn<IPartnerLicensesHistoryTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IPartnerLicensesHistoryTableRow> = {
  selection: false,
};

const PartnerLicensesHistoryTable = (props: IPartnerLicensesHistoryTableProps) => {
  const {
    partnerLicensesHistory: prePartnerLicensesHistory,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedPartnerLicensesHistory,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();

  const partnerLicensesHistory = useMemo(() => prePartnerLicensesHistory.map(
    (licenseHistory) => ({
      ...licenseHistory,
      processedDate: formatDate({ date: licenseHistory.date }, DateTimeFormatEnum.DEFAULT),
      month: getMonthName({
        date: licenseHistory.date,
        locale: currentLanguage,
      }),
    }),
  ), [prePartnerLicensesHistory, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('partnerPage.licensesHistoryTab.date'),
      field: 'date',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.processedDate}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('partnerPage.licensesHistoryTab.licenses'),
      field: 'licenseCount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.licenseCount}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('partnerPage.licensesHistoryTab.description'),
      field: 'description',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {t('organizationPage.licensesHistoryTab.licenseDescription', { month: rowData.month })}
        </TypographyWithTooltip>
      ),
      width: '70%',
      sorting: false,
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={partnerLicensesHistory}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedPartnerLicensesHistory}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="date"
      rowHeight={51}
    />
  );
};

export default memo(PartnerLicensesHistoryTable);
