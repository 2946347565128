enum PubSubEventEnum {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  RESET_TABLE = 'RESET_TABLE',
  RESET_FILTER = 'RESET_FILTER',
  REFETCH_CURRENT_USER = 'REFETCH_CURRENT_USER',
  TRIGGER_TOKENS_REFRESH = 'TRIGGER_TOKENS_REFRESH',
  TOKENS_REFRESHED = 'TOKENS_REFRESHED',
}

export default PubSubEventEnum;
