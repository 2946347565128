import {
  memo, useCallback, useState, useMemo,
} from 'react';
import {
  WidgetContainer,
  useTranslations,
  useSnackbar,
  EditableContainer,
  DetailsElements,
} from '@uniqkey-frontend/shared-app';
import { MeResponse } from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../../../../../../enums/ACLEnum';
import {
  useGetPartnerById,
  useUpdatePartner,
} from '../../../../../../hooks/reactQuery';
import EditPartnerDetailsModal, {
  IEditPartnerDetailsModalReturnValue,
} from '../EditPartnerDetailsModal';
import { useUser } from '../../../../../../contexts/UserContext';

interface IPartnerDetailsWidgetProps {
  partnerId: string;
}

const MIN_CONTAINER_HEIGHT = 305;

const PartnerDetailsWidget = (props: IPartnerDetailsWidgetProps) => {
  const { partnerId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const { currentUser, userCan } = useUser();
  const { partnerType } = currentUser ?? {} as MeResponse;

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleEditModalOpen = useCallback(() => setIsEditModalOpen(true), []);
  const handleEditModalClose = useCallback(() => setIsEditModalOpen(false), []);

  const { data: partner, isLoading: isPartnerLoading, isError } = useGetPartnerById(
    { partnerId },
  );

  const { mutate, isLoading: isPartnerUpdating } = useUpdatePartner({
    partnerId,
    useOptimisticUpdates: true,
  });

  const handleEditPartnerDetails = useCallback((value: IEditPartnerDetailsModalReturnValue) => {
    mutate(
      value,
      {
        onError: () => showError({ text: t('common.somethingWentWrong') }),
        onSuccess: () => {
          showSuccess({ text: t('partnerPage.toast.detailsUpdated') });
          handleEditModalClose();
        },
      },
    );
  }, [mutate, handleEditModalClose, showError, showSuccess, t]);

  const elements = useMemo(() => {
    const {
      name, zip, address, vatNumber, country, city, type,
    } = partner ?? {};
    return {
      name, zip, address, vatNumber, country, city, type,
    };
  }, [partner]);

  if (isError) {
    return null;
  }

  return (
    <WidgetContainer
      container
      p={0}
      pb={3}
      isLoading={isPartnerLoading}
      minHeight={MIN_CONTAINER_HEIGHT}
      withShadow
    >
      <EditableContainer
        container
        item
        p={3}
        rowGap={2}
        minHeight={MIN_CONTAINER_HEIGHT}
        onClick={handleEditModalOpen}
        disabled={!userCan(ACLEnum.PartnerEdit)}
      >
        <DetailsElements
          hidden={!partner}
          elements={elements}
          translationKey="partnerDetailsWidget"
          t={t}
        />
      </EditableContainer>
      {isEditModalOpen && (
        <EditPartnerDetailsModal
          isOpen={isEditModalOpen}
          isLoading={isPartnerUpdating}
          onSubmit={handleEditPartnerDetails}
          onClose={handleEditModalClose}
          partner={partner!}
          type={partnerType}
        />
      )}
    </WidgetContainer>
  );
};

export default memo(PartnerDetailsWidget);
