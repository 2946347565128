import { AxiosResponse } from 'axios';
import { GetAuditLogsResponseModel } from '@uniqkey-backend-partner/api-client';
import { PatchOperationEnum } from '@uniqkey-frontend/shared-app';
import { IAuditLog } from '../hooks/useAuditLogsAPI/interfaces';

export interface IReplacePatchOperation<Value = unknown> {
  op: PatchOperationEnum;
  path: string;
  value: Value;
}

export const createReplacePatchOperation = <Value = unknown>(
  path: string, value: Value,
) : IReplacePatchOperation<Value> => {
  const processedPath = path.startsWith('/') ? path : `/${path}`;
  return { op: PatchOperationEnum.Replace, path: processedPath, value };
};

export const parseReplacePatchOperation = <Value = unknown>(
  operation: IReplacePatchOperation<Value>,
) => {
  const { op, path, value } = operation;
  if (op !== PatchOperationEnum.Replace) {
    throw new Error(`Method "${
      parseReplacePatchOperation.name
    }" parses only "${PatchOperationEnum.Replace}" operations.`);
  }
  const processedPath = path.startsWith('/') ? path.substring(1) : path;
  return { [processedPath]: value };
};

export const dataExtractor = <T>() => (response: AxiosResponse<T>): T => response.data;

export const auditLogPayloadExtractor = (auditLog: GetAuditLogsResponseModel): IAuditLog => {
  let parsedPayload;
  let parsedEnrichedPayload;
  try {
    parsedPayload = JSON.parse(auditLog.payload);
  } catch (e) {
    parsedPayload = {};
  }
  try {
    parsedEnrichedPayload = JSON.parse(auditLog.enrichedPayload);
  } catch (e) {
    parsedEnrichedPayload = {};
  }
  return { ...auditLog, parsedPayload, parsedEnrichedPayload };
};
