import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import PartnerOrganizationEmployeePageBreadcrumbs from
  './components/PartnerOrganizationEmployeePageBreadcrumbs';

const PartnerOrganizationEmployeePage = () => {
  const params = useParams();
  const { partnerId, organizationId, employeeAccountId } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <PartnerOrganizationEmployeePageBreadcrumbs
          employeeAccountId={employeeAccountId!}
          organizationId={organizationId!}
          partnerId={partnerId!}
        />
      </Grid>
      <Grid item xs>
        <Outlet context={{ employeeAccountId, organizationId, partnerId }} />
      </Grid>
    </Grid>
  );
};

export default PartnerOrganizationEmployeePage;
