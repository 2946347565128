import { memo } from 'react';
import { Panel, PanelContent } from '@uniqkey-frontend/shared-app';
import { PartnerUserRole } from '@uniqkey-backend-partner/api-client';
import RoleTable from '../../../../components/tables/RoleTable';
import useRoleTable from '../../../../hooks/tables/useRoleTable';

interface IRoleModuleProps {
  role: PartnerUserRole;
}

const RoleModule = (props: IRoleModuleProps) => {
  const { role } = props;

  const tableProps = useRoleTable({
    persistentFilters: { role },
    noDataMessageKey: 'table.noData.default',
  });

  return (
    <Panel>
      <PanelContent p={0}>
        <RoleTable {...tableProps} />
      </PanelContent>
    </Panel>
  );
};

export default memo(RoleModule);
