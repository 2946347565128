import { useMemo, memo } from 'react';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
} from '@uniqkey-frontend/shared-app';
import { prepareAuditLogRenderData } from '../../../helpers/auditLogs';
import {
  IOrganizationEmployeeAccountAuditLog,
} from '../../../hooks/useEmployeeAccountsAPI/interfaces';

// eslint-disable-next-line max-len
export interface IOrganizationEmployeeAuditLogsTableRow extends IOrganizationEmployeeAccountAuditLog {
  processedDate: string | null;
  processedActionDoneBy: string | null;
  processedDescription: string;
}

interface IOrganizationEmployeeAuditLogsTableProps extends
  Omit<
    IUseTableReturn<IOrganizationEmployeeAuditLogsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Omit<IUseTableFetchReturn<IOrganizationEmployeeAccountAuditLog>, 'data' | 'refetch'>
{
  auditLogs: IUseTableFetchReturn<IOrganizationEmployeeAccountAuditLog>['data'];
  selectedAuditLogs: IUseTableReturn<IOrganizationEmployeeAuditLogsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationEmployeeAuditLogsTableRow> = {
  selection: false,
};

const OrganizationEmployeeAuditLogsTable = (props: IOrganizationEmployeeAuditLogsTableProps) => {
  const {
    auditLogs: preAuditLogs,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    onColumnOrderByChange,
    onColumnOrderDirectionChange,
    selectedAuditLogs,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();

  const auditLogs = useMemo(
    () => preAuditLogs.map((auditLog) => prepareAuditLogRenderData(t, auditLog)),
    [t, preAuditLogs],
  );

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationEmployeePage.auditLogsTab.date'),
      field: 'processedDate',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.processedDate}
        </TypographyWithTooltip>
      ),
      width: '18%',
      sorting: false,
    },
    {
      title: t('organizationEmployeePage.auditLogsTab.actionDoneBy'),
      field: 'actorEmail',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.processedActionDoneBy}
        </TypographyWithTooltip>
      ),
      width: '25%',
      sorting: false,
    },
    {
      title: t('organizationEmployeePage.auditLogsTab.description'),
      field: 'description',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.processedDescription}
        </TypographyWithTooltip>
      ),
      width: '57%',
      sorting: false,
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={auditLogs}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      onColumnOrderByChange={onColumnOrderByChange}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedAuditLogs}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="createdAt"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationEmployeeAuditLogsTable);
