import { memo } from 'react';
import { PanelContent, Grid } from '@uniqkey-frontend/shared-app';
import OrganizationEmployeeDetailsWidget from './components/OrganizationEmployeeDetailsWidget';
import OrganizationEmployeeLoginsWidget from './components/OrganizationEmployeeLoginsWidget';

interface IOrganizationEmployeeDetailsTabProps {
  employeeAccountId: string;
  organizationId: string;
}

const OrganizationEmployeeDetailsTab = (props: IOrganizationEmployeeDetailsTabProps) => {
  const { employeeAccountId, organizationId } = props;

  return (
    <PanelContent p={3} pt={1} className="height-100-percent">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <OrganizationEmployeeDetailsWidget employeeAccountId={employeeAccountId} />
        </Grid>
        <Grid item container xs={6}>
          <Grid item container xs={6}>
            <OrganizationEmployeeLoginsWidget
              employeeAccountId={employeeAccountId}
              organizationId={organizationId}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </Grid>
    </PanelContent>
  );
};

export default memo(OrganizationEmployeeDetailsTab);
