import { useMemo, memo, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { PartnerUserRole } from '@uniqkey-backend-partner/api-client';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { USER_ROLES_TRANSLATION_KEYS } from '../../../../constants';

interface IRolePageBreadcrumbsProps {
  role: PartnerUserRole;
}
const RolePageBreadcrumbs = (props: IRolePageBreadcrumbsProps) => {
  const { role } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();

  const roleTranslationKey = USER_ROLES_TRANSLATION_KEYS[
    role as keyof typeof USER_ROLES_TRANSLATION_KEYS
  ];

  const breadcrumbsNodes = useMemo(() => {
    if (!roleTranslationKey) {
      return [];
    }
    return [
      { title: t('navigation.roles'), onClick: () => navigate(PageRouteEnum.Roles) },
      { title: t(roleTranslationKey) },
    ];
  }, [roleTranslationKey, navigate, t]);

  useEffect(() => {
    if (!roleTranslationKey) {
      showError({ text: t('common.somethingWentWrong') });
    }
  }, [roleTranslationKey, showError, t]);

  if (!roleTranslationKey) {
    return <Navigate to={PageRouteEnum.Roles} />;
  }

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(RolePageBreadcrumbs);
