import PubSub from 'pubsub-js';
import {
  LoginApi,
  LoginTwoFactorRequest,
  SetTwoFactorRequest,
} from '@uniqkey-backend-partner/api-client';
import {
  derivePrivateKey, deriveSession, deriveVerifier, generateEphemeral, generateSalt, verifySession,
} from 'secure-remote-password/client';
import { decode, type JwtPayload } from 'jsonwebtoken';
import LocalStorageKeyEnum from '../../enums/LocalStorageKeyEnum';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import { axiosInstance } from '../../axios';
import config from '../../config';

interface ILoginParams {
  email: string,
  password: string;
}

export interface ILogoutParams {
  showMessage: boolean;
}

interface ISetupMasterPasswordParams {
  id: string;
  password: string;
}

const authClient = new LoginApi(
  undefined,
  config.getPartnerApiUrl(),
  axiosInstance,
);

export const getJWTToken = (): string | null => localStorage.getItem(LocalStorageKeyEnum.JwtToken);
export const setJWTToken = (token: string | null): void => {
  if (!token) {
    localStorage.removeItem(LocalStorageKeyEnum.JwtToken);
    return;
  }
  localStorage.setItem(LocalStorageKeyEnum.JwtToken, token);
};

export const getRefreshToken = (): string | null => (
  localStorage.getItem(LocalStorageKeyEnum.RefreshToken)
);
export const setRefreshToken = (token: string | null): void => {
  if (!token) {
    localStorage.removeItem(LocalStorageKeyEnum.RefreshToken);
    return;
  }
  localStorage.setItem(LocalStorageKeyEnum.RefreshToken, token);
};

export const logout = (params?: ILogoutParams) => {
  setJWTToken(null);
  setRefreshToken(null);
  PubSub.publish(PubSubEventEnum.LOGOUT, params);
};

export const login = async (params: ILoginParams) => {
  const { password, email } = params;
  const { data } = await authClient.apiV1LoginLoginStepOnePost({ email });
  const { id, salt, serverEphemeralPublic } = data;
  const clientEphemeral = generateEphemeral();
  const privateKey = derivePrivateKey(salt, id, password);
  const clientSession = deriveSession(
    clientEphemeral.secret,
    serverEphemeralPublic,
    salt,
    id,
    privateKey,
  );

  const { data: step2Response } = await authClient.apiV1LoginLoginStepTwoPost({
    id,
    email,
    clientEphemeralPublic: clientEphemeral.public,
    clientSessionProof: clientSession.proof,
  });
  const {
    serverSessionProof,
    refreshToken: loginRefreshToken,
    jwtToken: loginJWTToken,
  } = step2Response;
  verifySession(clientEphemeral.public, clientSession, serverSessionProof);
  setJWTToken(loginJWTToken);
  setRefreshToken(loginRefreshToken);
  PubSub.publish(PubSubEventEnum.LOGIN);
};

export const setupMasterPassword = async (params: ISetupMasterPasswordParams) => {
  const { id, password } = params;
  const salt = generateSalt();
  const privateKey = derivePrivateKey(salt, id, password);
  const verifier = deriveVerifier(privateKey);
  await authClient.apiV1LoginChangeMasterPasswordPost({ verifier, salt });
  PubSub.publish(PubSubEventEnum.REFETCH_CURRENT_USER);
};

export const setupTwoFA = async (params: SetTwoFactorRequest) => {
  const { twoFactorSecret, otpCode } = params;
  await authClient.apiV1LoginSetTwoFactorPost({ twoFactorSecret, otpCode });
};

export const loginTwoFA = async (params: LoginTwoFactorRequest) => {
  const { otpCode } = params;
  const { data } = await authClient.apiV1LoginTwoFactorLoginPost({ otpCode });
  const {
    jwtToken,
    refreshToken,
  } = data;
  setJWTToken(jwtToken);
  setRefreshToken(refreshToken);
  PubSub.publish(PubSubEventEnum.REFETCH_CURRENT_USER);
};

export const getCurrentTimeInSeconds = (): number => Math.floor(Date.now() / 1000);

export const getLastActivityTS = (): number => {
  const lastActivityTS = localStorage.getItem(LocalStorageKeyEnum.LastActivityTS);
  const parsedLastActivityTS = parseInt(lastActivityTS as string, 10);
  return Number.isNaN(parsedLastActivityTS) ? 0 : parsedLastActivityTS;
};

export const setLastActivityTS = (time?: number): void => {
  const now = time ?? getCurrentTimeInSeconds();
  localStorage.setItem(LocalStorageKeyEnum.LastActivityTS, now.toString());
};

export const removeLastActivityTS = (): void => {
  localStorage.removeItem(LocalStorageKeyEnum.LastActivityTS);
};

export const getDecodedJwtToken = (): JwtPayload | null => {
  const token = getJWTToken();
  if (!token) {
    return null;
  }
  return decode(token) as JwtPayload;
};

export const isAuthenticated = () => !!getJWTToken();
