import { Grid } from '@uniqkey-frontend/shared-app';
import ProfileModule from './components/ProfileModule';

const ProfilePage = () => (
  <Grid container flexDirection="column" className="height-100-percent">
    <Grid item xs>
      <ProfileModule />
    </Grid>
  </Grid>
);

export default ProfilePage;
