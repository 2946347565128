import { memo } from 'react';
import {
  PanelContent,
  ScrollablePanelContent,
  Grid,
  CircularProgress,
  Box,
} from '@uniqkey-frontend/shared-app';
import { useGetOrganizationById } from '../../../../hooks/reactQuery';
import OrganizationUserDetailsWidget from './components/OrganizationUserDetailsWidget';
import OrganizationDetailsWidget from './components/OrganizationDetailsWidget';

interface IOrganizationDetailsTabProps {
  organizationId: string;
}

const OrganizationDetailsTab = (props: IOrganizationDetailsTabProps) => {
  const { organizationId } = props;

  const { data: organization, isLoading, isError } = useGetOrganizationById(
    { organizationId },
  );

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <PanelContent p={3} className="height-100-percent">
        <Grid container alignItems="center" justifyContent="center" className="height-100-percent">
          <CircularProgress />
        </Grid>
      </PanelContent>
    );
  }

  return (
    <ScrollablePanelContent p={3} pt={1}>
      <OrganizationUserDetailsWidget
        organization={organization!}
        isLoading={isLoading}
        isError={isError}
      />
      <Box mt={3} />
      <OrganizationDetailsWidget
        organizationId={organizationId}
        organization={organization!}
        isLoading={isLoading}
        isError={isError}
      />
    </ScrollablePanelContent>
  );
};

export default memo(OrganizationDetailsTab);
