import { useMemo } from 'react';
import { useLanguages as useLanguagesTransifex } from '@transifex/react';
import { ILanguage } from '@transifex/native';
import { useQuery } from 'react-query';
import { GetAllSupportedUILanguagesResponse } from '@uniqkey-backend-partner/api-client';
import { fetchSupportedLanguages } from '../../../api/supportedLanguagesApi';

const REACT_QUERY_SUPPORTED_LANGUAGES_KEY = 'REACT_QUERY_SUPPORTED_LANGUAGES_KEY';

const useLanguages = () => {
  const transifexLanguages: ILanguage[] = useLanguagesTransifex();

  const {
    isLoading: isSupportedLanguagesRequestLoading,
    data,
  } = useQuery<GetAllSupportedUILanguagesResponse>(
    [REACT_QUERY_SUPPORTED_LANGUAGES_KEY],
    ({ signal }) => fetchSupportedLanguages({ signal }),
    {
      notifyOnChangeProps: 'tracked',
    },
  );

  const isLoading = !transifexLanguages.length || isSupportedLanguagesRequestLoading;
  const languages = useMemo(() => {
    if (isLoading) return [];

    if (!data?.supportedUILanguages) return [];

    const { supportedUILanguages } = data;

    if (!supportedUILanguages?.length) return [];

    const supportedLanguagesCodes = supportedUILanguages
      .map((supportedUILanguage) => supportedUILanguage.isoCode);

    return transifexLanguages
      .filter((transifexLanguage) => supportedLanguagesCodes.includes(transifexLanguage.code));
  }, [data, transifexLanguages, isLoading]);

  return {
    isLoading,
    languages,
  };
};

export default useLanguages;
