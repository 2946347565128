/* eslint-disable max-len */
const config = {
  requestTimeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT as string, 10) ?? 10000,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  useLocalTranslations: process.env.REACT_APP_USE_LOCAL_TRANSLATIONS === 'true',
  getPartnerApiUrl: () => `https://partner-api.${process.env.REACT_APP_ENVIRONMENT}.next.uniqkey.eu`,
};

export default config;
