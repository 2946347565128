import { useCallback, useState } from 'react';
import {
  B1,
  Box,
  Button,
  ClosedEyeIcon,
  EyeIcon,
  Grid,
  IconButton,
  InputAdornment,
  LogoIcon,
  minLengthValidator,
  Panel,
  ReactHookFormTextField,
  S5,
  S6,
  stringsMatchValidator,
  Typography,
  useSnackbar,
  useTranslations,
  validateDigit,
  validateLowerCaseLetter,
  validateSpecialCharacter,
  validateUpperCaseLetter,
  ValidationGroup,
  ValidationItem,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { MeResponse } from '@uniqkey-backend-partner/api-client';
import { logException } from '../../services/sentryService';
import { setupMasterPassword } from '../../services/authService';
import { useUser } from '../../contexts/UserContext';
import UnauthenticatedStepHelperSection from '../../components/UnauthenticatedStepHelperSection';

interface ISetupMasterPasswordFormValue {
  masterPassword: string;
  confirmMasterPassword: string;
}

const MIN_WIDTH = 720;
const MIN_HEIGHT = 726;

const SetupMasterPasswordPage = () => {
  const { t } = useTranslations();
  const { showSuccess, showError } = useSnackbar();
  const { currentUser } = useUser();
  const { id } = currentUser ?? {} as MeResponse;

  const [isSetupMasterPasswordLoading, setIsSetupMasterPasswordLoading] = useState(false);
  const [isMasterPasswordShown, setIsMasterPasswordShown] = useState(false);
  const [isConfirmMasterPasswordShown, setIsConfirmMasterPasswordShown] = useState(false);

  const {
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    trigger,
    control,
  } = useForm<ISetupMasterPasswordFormValue>({
    criteriaMode: 'all',
    mode: 'all',
    defaultValues: {
      confirmMasterPassword: '',
      masterPassword: '',
    },
  });

  const toggleMasterPasswordShown = useCallback(
    () => setIsMasterPasswordShown(!isMasterPasswordShown),
    [isMasterPasswordShown],
  );

  const toggleConfirmMasterPasswordShown = useCallback(
    () => setIsConfirmMasterPasswordShown(!isConfirmMasterPasswordShown),
    [isConfirmMasterPasswordShown],
  );

  const handleConfirmMasterPasswordChange = useCallback(() => {
    trigger();
  }, [trigger]);

  const handleOnSubmit = useCallback(async (form: ISetupMasterPasswordFormValue) => {
    try {
      setIsSetupMasterPasswordLoading(true);
      const { masterPassword: password } = form;
      await setupMasterPassword({ id, password });
      showSuccess({ text: t('setupMasterPasswordPage.passwordCreatedToast') });
      // do not set 'isLoading' to 'false' here so the submit button remains disabled
    } catch (e) {
      setIsSetupMasterPasswordLoading(false);
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'SetupMasterPasswordPage/handleOnSubmit exception',
      });
    }
  }, [showError, showSuccess, id, t]);

  return (
    <Grid
      container
      className="min-height-100-vh"
      alignItems="center"
      justifyContent="center"
    >
      <Box my={5}>
        <Panel>
          <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete="off">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
              rowGap={8}
              p={4}
              minWidth={MIN_WIDTH}
              minHeight={MIN_HEIGHT}
            >
              <Grid item container alignItems="center" justifyContent="center">
                <LogoIcon height={60} width={210} color={B1} />
              </Grid>
              <Grid item>
                <Typography color={S6}>
                  {t('setupMasterPasswordPage.title')}
                </Typography>
              </Grid>
              <Grid item container alignItems="center" justifyContent="center" rowGap={3}>
                <Grid item container xs={9}>
                  <ReactHookFormTextField
                    control={control}
                    id="new_password"
                    fullWidth
                    autoFocus
                    name="masterPassword"
                    type={isMasterPasswordShown ? 'text' : 'password'}
                    error={!!errors.masterPassword}
                    label={t('setupMasterPasswordPage.createMasterPassword.label')}
                    placeholder={t('setupMasterPasswordPage.createMasterPassword.placeholder')}
                    rules={{
                      validate: {
                        specialCharacter: (password) => validateSpecialCharacter(password),
                        digit: (password) => validateDigit(password),
                        upperCaseLetter: (password) => validateUpperCaseLetter(password),
                        lowerCaseLetter: (password) => validateLowerCaseLetter(password),
                        minLength: (password) => minLengthValidator(12, password),
                        passwordsMatch: (password) => stringsMatchValidator(
                          getValues().confirmMasterPassword,
                          password,
                          { caseSensitive: true },
                        ),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={toggleMasterPasswordShown}
                          >
                            {isMasterPasswordShown ? <ClosedEyeIcon /> : <EyeIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item container xs={9}>
                  <ReactHookFormTextField
                    id="password_confirmation"
                    name="confirmMasterPassword"
                    onChange={handleConfirmMasterPasswordChange}
                    control={control}
                    fullWidth
                    type={isConfirmMasterPasswordShown ? 'text' : 'password'}
                    error={!!errors.confirmMasterPassword}
                    label={t('setupMasterPasswordPage.confirmMasterPassword.label')}
                    placeholder={t('setupMasterPasswordPage.confirmMasterPassword.placeholder')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={toggleConfirmMasterPasswordShown}
                          >
                            {isConfirmMasterPasswordShown ? <ClosedEyeIcon /> : <EyeIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                rowGap={2}
              >
                <Grid item container alignItems="center" justifyContent="center">
                  <Typography variant="subtitle1" color={S5}>
                    {t('setupMasterPasswordPage.validationAreaDescription')}
                  </Typography>
                </Grid>
                <Grid item xs={4.5} />
                <Grid item xs={5}>
                  <ValidationGroup alignItems="center" justifyContent="flex-start">
                    <ValidationItem
                      isDirty={isDirty}
                      isValid={!errors.masterPassword?.types?.minLength}
                    >
                      {t('setupMasterPasswordPage.includeMinimumDigits')}
                    </ValidationItem>
                    <ValidationItem
                      isDirty={isDirty}
                      isValid={
                      !errors.masterPassword?.types?.upperCaseLetter
                      && !errors.masterPassword?.types?.lowerCaseLetter
                    }
                    >
                      {t('setupMasterPasswordPage.includeUppercaseAndLowercaseLetters')}
                    </ValidationItem>
                    <ValidationItem
                      isDirty={isDirty}
                      isValid={!errors.masterPassword?.types?.digit}
                    >
                      {t('setupMasterPasswordPage.includeNumbers')}
                    </ValidationItem>
                    <ValidationItem
                      isValid={!errors.masterPassword?.types?.specialCharacter}
                      isDirty={isDirty}
                    >
                      {t('setupMasterPasswordPage.includeSymbols')}
                    </ValidationItem>
                    <ValidationItem
                      isValid={!errors.masterPassword?.types?.passwordsMatch}
                      isDirty={isDirty}
                    >
                      {t('setupMasterPasswordPage.passwordsMatch')}
                    </ValidationItem>
                  </ValidationGroup>
                </Grid>
                <Grid item xs={2.5} />
              </Grid>
              <Grid item container xs={4}>
                <Button
                  isLoading={isSetupMasterPasswordLoading}
                  type="submit"
                  fullWidth
                  disabled={!isDirty || !!errors.masterPassword}
                >
                  {t('common.confirm')}
                </Button>
              </Grid>
              <UnauthenticatedStepHelperSection />
            </Grid>
          </form>
        </Panel>
      </Box>
    </Grid>
  );
};

export default SetupMasterPasswordPage;
