import { useCallback, useMemo, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  buildFilterOptionsAsObject,
  usePubSub,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  GetPartnersResponsePartner,
  PartnerType,
} from '@uniqkey-backend-partner/api-client';
import { IGetPartnersParams } from '../../../../hooks/usePartnersAPI/interfaces';
import { getPartnerTypeOptions } from '../../../../helpers/partnerType';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import { COUNTRIES_LIST } from '../../../../helpers/countries';

export interface IPartnersListFilterSubmitResult {
  countryFilter: IGetPartnersParams['countryFilter'];
  typeFilter: IGetPartnersParams['typeFilter'];
}

interface IPartnersListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IPartnersListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IPartnersListFilterSubmitResult;
  type: PartnerType;
}

type TTypeFilterOption = {
  label: string;
  value: PartnerType;
} | null;

interface IFormValues {
  countryFilter: GetPartnersResponsePartner['country'] | null;
  typeFilter: TTypeFilterOption;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const PartnersListFilter = (props: IPartnersListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues, type,
  } = props;
  const { t } = useTranslations();

  const [typeFilterOptions, typeFilterOptionsAsObject] = useMemo(() => {
    const options = getPartnerTypeOptions(t, type);
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [type, t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      countryFilter: initialValues.countryFilter ?? null,
      typeFilter: typeFilterOptionsAsObject[
        initialValues.typeFilter as keyof typeof typeFilterOptionsAsObject
      ] ?? null,
    },
  });

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const { countryFilter, typeFilter } = value;
    onSubmit({
      countryFilter: countryFilter ?? undefined,
      typeFilter: typeFilter?.value,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('countryFilter', null, { shouldDirty: true });
    setValue('typeFilter', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      countryFilter: null,
      typeFilter: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('partnersFilter.country.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="countryFilter"
                  placeholder={t('partnersFilter.country.placeholder')}
                  options={COUNTRIES_LIST}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('partnersFilter.type.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="typeFilter"
                  placeholder={t('partnersFilter.type.placeholder')}
                  options={typeFilterOptions}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(PartnersListFilter);
